@tailwind base;
@tailwind components;
@tailwind utilities;

/*
* App Global CSS
* ----------------------------------------------------------------------------
* Put style rules here that you want to apply globally. These styles are for
* the entire app and not just one component. Additionally, this file can be
* used as an entry point to import other CSS/Sass files to be included in the
* output CSS.
* For more information on global stylesheets, visit the documentation:
* https://ionicframework.com/docs/layout/global-stylesheets
*/

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import 'theme/css/bootstrap-select.css';
@import 'theme/css/font-awesome.min.css';
@import 'theme/css/material-design-iconic-font.min.css';
@import 'theme/css/slider.css';
@import 'theme/css/swiper.css';

// global.css
@import 'https://fonts.googleapis.com/css2?family=Poppins:wght@600;800&display=swap';

.gm-style-iw > button {
  display: none;
}

.gm-style-iw-c > button {
  display: none;
}

//.address-search-modal .modal-wrapper{.address-search-modalmodal-wrapperheight:95%;width:100%;bottom:0;border-top-left-radius:15px;border-top-right-radius:15px;position:absolute;display:block;overflow:visible}
// .address-search-modal .modal-wrapper:after{}
.picker-columns.sc-ion-picker-ios {
  padding-left: 30px;
  padding-right: 30px;
}

.info-alert-modal .modal-wrapper {
  height: 60%;
  width: 100%;
  bottom: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  position: absolute;
  display: block;
}

.joker_page_full .modal-wrapper {
  height: 95%;
  width: 95%;
  position: absolute;
  display: block;
}

ion-modal.fixed-height-modal {
  --height: 90%;
  --max-height: 90%;
  --min-height: 90%;
}

ion-modal.fatura-ekle-modal {
  --height: 100%;
  --max-height: 733px;
  --min-height: 600px;
}

.home-country-select-alert {
  height: 20%;
}

ion-action-sheet {
  --height: 50% !important;
}

.site_button {
  display: inline-block;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  background-color: #ccc;
  border-radius: 100px;
  padding: 20px 0;
  box-shadow: 0 4px 23px rgba(0, 0, 0, 0.15);
}

.icon_button {
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  background-color: #ccc;
  border-radius: 100px;
  padding: 20px 0;
}

.bg_orange {
  color: #fff;
  background: linear-gradient(90deg, #e45c0f 0%, #e8793b 100%);
}

.bg_orange:hover {
  background: #e45c0f;
}

.rows {
  float: left;
  width: 100%;
}

.bg_turquoise {
  color: #fff;
  background: linear-gradient(90deg, #01a0ac 0%, #06d5e4 100%);
}

.bg_turquoise:hover {
  background: #01a0ac;
}

.top20 {
  margin-top: 20px;
}

.highlight {
  color: #f15a24 !important;
}

.tabs nav a.highlight:before {
  width: 100% !important;
}

.title-default {
  color: #f15a24;
  font-weight: 800;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  line-height: 18px;
  --text-overflow: clip !important;
  --white-space: normal !important;
  padding: 0 !important;
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

ion-button.in-toolbar {
  z-index: 9;
  position: relative;
}

// ion-button.button.button-clear.in-toolbar.ion-activatable.ion-focusable{}
// .header-ios ion-toolbar:last-of-type{}
ion-toolbar.in-toolbar {
  display: flex;
  justify-content: center;
  // box-shadow: 0 3px 27px #000;
  --border-width: 0 0 0 !important;
}

ion-button.button.button-full.button-round.button-solid.ion-activatable.ion-focusable {
  height: 54px;
  font-weight: 700;
  float: left;
  width: 100%;
}

.native-input {
  text-align: left !important;
  font-size: 16px !important;
  padding-top: 3px !important;
  padding-bottom: 5px !important;
  font-weight: 700 !important;
}

.native-textarea {
  text-align: left !important;
  font-size: 16px !important;
  padding-top: 3px !important;
  padding-bottom: 5px !important;
  font-weight: 700 !important;
}

.lbl_textbox {
  font-size: 12px !important;
  padding-left: 0 !important;
}

ion-select {
  text-align: left !important;
  font-size: 16px !important;
  padding-left: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 4px !important;
  font-weight: 700 !important;
}

.bg_red {
  color: #fff;
  background: linear-gradient(90deg, #f00000 0%, #c62118 100%);
}

.bg_turquoise {
  color: #fff;
  background: linear-gradient(90deg, #01a0ac 0%, #06d5e4 100%);
}

.smspage .native-input {
  padding-left: 20px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.forminput {
  padding: 0 5px;
  border: 1px solid #919395;
  border-radius: 5px;
  font-weight: 700;
  float: left;
  width: 100%;
  margin-bottom: 10px;
}

.forminput ion-input {
  border-bottom: 0 !important;
}

.forminput .select-item {
  border-bottom: 0 !important;
}

.forminput_50_row {
  display: flex;
  justify-content: space-between;
}

.forminput_50 {
  border-radius: 5px;
  font-weight: 700;
  float: left;
  width: 48%;
}

.forminput_30 {
  border-radius: 5px;
  font-weight: 700;
  float: left;
  width: 29%;
}

.forminput_70 {
  border-radius: 5px;
  font-weight: 700;
  float: left;
  width: 69%;
}

.forminput_50_row ion-input {
  border-bottom: 0 !important;
}

.forminput_50_row .select-item {
  border-bottom: 0 !important;
}

.ccard_box {
  float: left;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 11px 44px rgba(0, 0, 0, 0.14);
  border-radius: 15px;
  padding: 25px;
  overflow: hidden;
  min-height: 100%;
}

ion-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

ion-input {
  border-bottom: 0 !important;
}

.forminput ion-select {
  border-bottom: 0 !important;
}

.forminput ion-textarea {
  border: 0 !important;
}

ion-tab-bar {
  height: 60px;
  contain: none !important;
}

.select-item {
  width: 100%;
  margin-top: 0;
  font-weight: 700;
  font-size: 16px;
  --padding-start: 0;
  --min-height: 31px !important;
  --padding-end: 0 !important;
}

.app_subtittle_total {
  float: left;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}

.app_subtittle_total_left {
  font-size: 22px;
  font-weight: 700;
  color: #999;
}

.app_subtittle_total_right {
  font-size: 22px;
  font-weight: 700;
}

ion-datetime {
  text-align: left;
  border-bottom: solid 1px #c4c4c4;
  width: 100%;
  margin-top: 5px;
  font-size: large;
  font-weight: 700;
  background-color: transparent !important;
  padding: 10px 0;
}

.park-box {
  background-color: #fff;
  width: 100%;
  margin-bottom: 5px;
  box-shadow: #000 0 -3px 19px;
  padding: 20px;
  padding-top: 0;
  padding-bottom: 6px;
}

.park-box-bottom {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.park-box-img {
  margin: 0;
  position: absolute;
  top: 50%;
}

.park-box-info h1 {
  font-weight: 700;
  color: #e45c0f;
  width: 100%;
  margin-top: 0;
}

.park-box-info h5 {
  color: #e45c0f;
  float: left;
  width: 100%;
  margin-top: 0;
  padding: 4px 0;
  border-radius: 5px;
  font-size: 14px;
  margin-left: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  margin-bottom: 5px;
  background-color: #eee !important;
}

.park-box-tittle h5 {
  text-align: left;
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
}

.park-box_img img {
  border-radius: 15px;
  width: 138px;
  height: 138px;
}

.park-box_img h5 {
  text-align: left;
}

.park-box-info {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  text-align: center;
  justify-content: center;
}

.park-box_img {
  width: 48%;
}

.park-bottom-button {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.park-bottom-button ion-button {
  font-size: 13px;
  font-weight: 700;
  width: 49.2%;
  margin: 5px 0;
  --border-radius: 5px;
}

ion-footer {
  padding: 20px 3%;
  background-color: #f8f9f9;
  padding-top: 5px;
  padding-bottom: 20px;
}

ion-tab-button {
  filter: grayscale(1);
  position: relative;
  z-index: 3;
}

.tab-selected {
  filter: grayscale(0) !important;
  -webkit-filter: grayscale(0) !important;
}

.tabs_bar_logo {
  filter: grayscale(0);
  transform: translate(0px, -13px);
  -webkit-filter: grayscale(0);
  -webkit-transform: translate(0px, -13px);
  -moz-transform: translate(0px, -13px);
  -ms-transform: translate(0px, -13px);
  -o-transform: translate(0px, -13px);
  height: 77px;
}

.tabs_bar_logo_icon {
  box-shadow: 0 0 10px #000;
  border-radius: 50%;
  width: 60px;
  overflow: visible;
  height: 60px;
  background-color: #f15a24 !important;
}

.app_fixfooter {
  position: relative;
  background-color: #f0f0f0;
  --background: #f0f0f0;
  border: 0;
  z-index: 3;
}

.wallet {
  float: left;
  width: 100%;
}

.top10 {
  margin-top: 10px;
}

ion-label {
  margin-top: 0;
  margin-bottom: 0;
  --min-height: 30px !important;
}

.tl_bakiye {
  font-family: Arial !important;
}

.carbox_options {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 14px;
  margin-top: 10px;
  position: absolute;
  bottom: -7px;
  right: 8px;
}

.car_option_edit {
  width: 30px;
  flex: 1;
  margin-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.car_option_edit .site_button {
  padding: 0;
}

.car_option_edit .icon_button {
  padding: 0;
}

.car_option_delete {
  width: 30px;
  flex: 1;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.car_option_delete .site_button {
  padding: 0;
}

.car_option_delete .icon_button {
  padding: 0;
}

.title-label {
  color: #f15a24;
  font-weight: 800;
  font-family: 'Poppins', sans-serif;
  min-height: 57px;
  font-size: 17px;
}

.cont {
  background-color: #ffffff;
  background-image: none !important;
}

.app_main {
  // background-color: #ffffff;
  background-image: none !important;
  //overflow-y: auto;
  //min-height: calc(100%-98px) !important;
}

ion-input {
  background-color: transparent !important;
}

.select-item {
  --background: transparent !important;
}

ion-select {
  background-color: transparent !important;
}

ion-textarea {
  background-color: transparent !important;
}

ion-slide.swiper-slide.swiper-zoom-container .park-box {
  height: 242px;
  margin-bottom: 14px;
}

.country-code-picker {
  font-size: 15px !important;
}

.item-lines-full ion-label {
  opacity: 1;
  transition: all 0.5s ease-in-out;
}

.item-lines-full:hover ion-label {
  transition: all 0.5s ease-in-out;
}

.boxshadows {
  box-shadow: 0 3px 27px #00000038;
  border-radius: 10px;
  width: 100%;
  background-color: #fff;
}

.hizmet_yazi {
  opacity: 1;
  transition: all 0.5s ease-in-out;
}

ion-item:hover .hizmet_yazi {
  transition: all 0.5s ease-in-out;
}

.padding20 {
  padding: 20px;
}

.app_main_bg {
  // box-shadow: 0 4px 13px #000;
  background-color: #fff;
  padding: 20px 5%;
}

.app_main.app_main_nopadding {
  padding: 0 !important;
}

// ion-button.button.button-clear.in-toolbar.ion-activatable.ion-focusable{}
ion-title.title-default {
  text-align: center;
}

b,
strong {
  font-weight: 600;
}

.rentPkgLbl {
  font-size: 17px;
  text-align: left;
  padding-left: 30px;
  color: gray;
}

.rentPkgLblSelected {
  font-size: 17px;
  text-align: left;
  padding-left: 30px;
  color: #000;
}

ion-searchbar {
  --background: transparent !important;
  --box-shadow: 0 !important;
  --border-radius: 0 !important;
}

.footer-md::before {
  background-image: none !important;
}

.gm-style-iw > button {
  display: none !important;
}

.gm-style-iw-d {
  overflow: hidden !important;
}

.gm-style-iw {
  padding: 2px !important;
}

.sec-icon {
  color: #fff;
}

.slot-date {
  box-shadow: 2px 2px #e0dedead;
  background-color: #fff;
  padding: 5px 10px;
  margin: 15px 10px;
}

.slot-date h5 {
  font-size: 15px;
  font-weight: 700;
}

.slot-time {
  padding-left: 10px;
  font-size: 15px;
  display: flex;
  flex-wrap: wrap;
}

.slot-time a {
  margin: 5px;
  line-height: 25px;
}

.slot-time p {
  margin: 5px;
  line-height: 25px;
  border: 1px solid;
  padding: 2px 5px;
}

.slot-aktif {
  color: #9a9a9a;
}

.no-slot {
  background-color: #e6e6e6;
  margin: 10% 5%;
  font-size: 25px;
  text-align: center;
  font-weight: 800;
  line-height: 50px;
  padding: 20px 10px;
}

.car-selected-lbl {
  color: #000 !important;
}

.image-overlay {
  position: relative;
}

.image-overlay:after {
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  height: 18px;
  background-color: #ffff;
  content: '';
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.parallax-head {
  position: relative;
  z-index: 9;
}

.sozlesmedetay {
  padding: 20px 4%;
}

h3.help_baslik {
  padding: 0 4%;
  padding-bottom: 15px;
}

.product-list tr:first-child td {
  border-bottom: 1px solid #666;
  padding: 0 2px;
  margin: 0;
}

.product-list td {
  padding: 10px 0;
}

.product-list {
  border-spacing: 4px;
  border-collapse: separate;
}

.rez_confm_bottom_sozlesme {
  margin-top: 20px;
}

.rez_confm_bottom_sozlesme a {
  color: #000;
  text-decoration: none;
  font-weight: 700;
  text-align: left !important;
}

// .refresher-pulling,.refresher-refreshing{}
// .refresher-pulling-icon,.refresher-refreshing-icon{}

[ion-fixed] {
  min-height: calc(100% - 98px);
}

// main.css
/******************************************************
Body
******************************************************/
body {
  overflow-x: hidden;
  -webkit-text-size-adjust: 100%;
  font-family: 'AveNext', 'Trebuchet MS', sans-serif !important;
  font-weight: 400;
  letter-spacing: 0.075em;
}

strong {
  font-family: 'AveNext', 'Trebuchet MS', sans-serif !important;
}

button,
a {
  transition: all 0.5s ease-in-out;
  text-decoration: none;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

button:hover,
a:hover {
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

.requiredError {
  border: 2px solid red !important;
}

.requiredTextError {
  color: red !important;
}

.requiredTextError::after {
  background: red !important;
}

.disabled-link {
  pointer-events: none;
}

.cursor {
  cursor: pointer;
}

.floatleft {
  float: left !important;
}

.floatright {
  float: right !important;
}

.text_yesil {
  color: #9dcb3b;
}

.text_koyuyesil {
  color: #187475;
}

.text_mavi {
  color: #0197d2;
}

.text_buz {
  color: #4bcacc;
}

.text_pembe {
  color: #dd547d;
}

.text_koyumavi {
  color: #187475;
}

.text_koyusari {
  color: #9a6709;
}

.text_cokkoyuyesil {
  color: #3d5b0e;
}

.font_bold,
strong {
  font-weight: 700;
}

h1 {
  font-size: 38px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 25px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

small {
  font-size: 10px;
}

.font-25 {
  font-size: 25px;
}

.font-23 {
  font-size: 23px;
}

.font-20 {
  font-size: 20px;
}

.font-18 {
  font-size: 18px;
}

.font-16 {
  font-size: 16px;
}

.font-14 {
  font-size: 14px;
}

.font-12 {
  font-size: 12px;
}

.font-10 {
  font-size: 10px;
}

.text_white {
  color: #fff;
}

.text_black {
  color: #000;
}

.text_gray {
  color: #999;
}

.text_lightgray {
  color: #aeaeae;
}

.text_orange {
  color: #f15a24;
}

.text_center {
  text-align: center;
}

.text_left {
  text-align: left;
}

.text_right {
  text-align: right;
}

.top5 {
  margin-top: 5px;
}

.top10 {
  margin-top: 10px;
}

.top15 {
  margin-top: 15px;
}

.top20 {
  margin-top: 20px;
}

.top25 {
  margin-top: 25px;
}

.top30 {
  margin-top: 30px;
}

.left5 {
  margin-left: 5px;
}

.left10 {
  margin-left: 10px;
}

.left15 {
  margin-left: 15px;
}

.left20 {
  margin-left: 20px;
}

.left30 {
  margin-left: 30px;
}

.bottom_20 {
  padding-bottom: 20px;
}

.bg_beyaz {
  background-color: #fff;
}

.bg_acikgri {
  background-color: #f4f4f4;
}

.bg_yesil {
  background-color: #9dcb3b;
}

.bg_mavi {
  background-color: #4bcacc;
}

.bg_sari {
  background-color: #fdbb41;
}

.bg_pembe {
  background-color: #dd547d;
}

.gradient_yesil {
  border: 1px solid #929393;
  box-shadow: #dded77 1px 1px 0 inset;
  background: #c2df51;
  background: linear-gradient(#c2df51, #77b724);
}

.gradient_mavi {
  border: 1px solid #929393;
  box-shadow: #d4f8f8 1px 1px 0 inset;
  background: #b2f2f3;
  background: linear-gradient(#b2f2f3, #4ee1e3);
}

.gradient_sari {
  border: 1px solid #929393;
  box-shadow: #fee882 1px 1px 0 inset;
  background: #fed55a;
  background: linear-gradient(#fed55a, #fca128);
}

.gradient_bg {
  background: #97bede;
  background: linear-gradient(#97bede, #fff);
}

.margin_auto {
  display: block;
  margin: 0 auto;
}

.border_gri {
  border: 1px solid #d4d4d4;
}

.padding_link_20 {
  padding: 0 20px;
  display: block;
}

.padding_yan_20 {
  padding: 0 20px;
}

.padding_dik_20 {
  padding: 20px 0;
}

.padding_20 {
  padding: 20px;
}

.padding_dik_10 {
  padding: 10px 0;
}

.padding_10 {
  padding: 10px;
}

.shadow {
  box-shadow: #666 3px 3px 4px;
}

.shadowleft {
  box-shadow: #666 -1px 3px 4px;
}

.radius_3 {
  border-radius: 3px;
}

.radius_5 {
  border-radius: 5px;
}

.radius_8 {
  border-radius: 8px;
}

.linkblock {
  display: block;
}

.height1 {
  height: 1px;
}

.fullink {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.bg_gr {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.14));
}

.bg_grlink {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.14));
}

.bg_grlink:hover {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.14), rgba(0, 0, 0, 0));
}

.sitelink {
  display: inline-block;
  position: relative;
}

.sitelink::before {
  content: ' ';
  position: absolute;
  top: 100%;
  border-radius: 3px;
  left: 0;
  width: 100%;
  height: 3px;
  background: #f15a24;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  -webkit-transform-origin: right center;
  transform-origin: right center;
  z-index: 2;
  box-sizing: border-box;
  display: block;
}

.sitelink:hover {
  color: #f15a24;
  background-color: transparent;
  opacity: 0.75;
  opacity: 1;
}

.sitelink:hover:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

.sitelink_underline::after {
  content: ' ';
  position: absolute;
  top: 100%;
  border-radius: 3px;
  left: 0;
  width: 100%;
  height: 3px;
  background: #222;
  z-index: 1;
  box-sizing: border-box;
  display: block;
}

header.sitetittle {
  float: left;
  width: 100%;
  text-align: center;
  padding: 40px 0;
}

header.sitetittle h2 {
  font-weight: 700;
  font-size: 44px;
}

.ourservices_img img {
  border-radius: 10px;
}

.ourservices_details {
  text-align: left;
  padding: 18px 0;
  color: #4bcacc;
}

.ourservices_details h2 {
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 19px;
}

.ourservices_details span {
  font-weight: 700;
}

.home_app_download {
  float: left;
  width: 100%;
  background-color: #f0f2f7;
  margin-top: 142px;
}

.home_app_download_content {
  position: relative;
  height: 480px;
  float: left;
  width: 100%;
}

.home_app_download_img {
  position: absolute;
  bottom: 0;
  width: 50%;
}

.home_app_download_img img {
  display: block;
}

.home_app_download_content_right {
  float: right;
  width: 46%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
  align-content: center;
}

.home_app_download_content_right h3 {
  font-size: 33px;
  font-weight: 700;
  padding-bottom: 28px;
}

.home_app_download_content_right h5 {
  padding-bottom: 30px;
  font-size: 18px;
}

.home_app_download_link {
  float: left;
  width: 100%;
}

.home_app_download_link a {
  margin-right: 5px;
}

section.our_services {
  overflow: hidden;
}

/******************************************************
Header
******************************************************/
#header {
  float: left;
  width: 100%;
  position: relative;
}

.container {
  width: 960px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.rows {
  float: left;
  width: 100%;
}

.mobilmenu_butonyer {
  display: none;
}

.fullink {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 99;
  height: 100%;
}

.checkboxx label:after,
.radioo label:after {
  content: '';
  display: table;
  clear: both;
}

.checkboxx .cr,
.radioo .cr {
  background-color: #fff;
  border: 1px solid #ff532f;
  border-radius: 0.25em;
  color: #fff;
  display: inline-block;
  float: left;
  height: 20px;
  margin-right: 0.5em;
  position: relative;
  width: 19px;
  margin-top: 0;
}

.radioo .cr {
  border-radius: 50%;
}

.checkboxx .cr .cr-icon,
.radioo .cr .cr-icon {
  color: #fff;
  font-size: 14px;
  left: 16%;
  line-height: 0;
  position: absolute;
  top: 50%;
}

.checkboxx label input[type='checkbox']:checked + .cr,
.radioo label input[type='radio']:checked + .cr {
  background-color: #ff532f;
}

.radioo .cr .cr-icon {
  margin-left: 0.04em;
}

.checkboxx label input[type='checkbox'],
.radioo label input[type='radio'] {
  display: none;
}

.checkboxx label input[type='checkbox'] + .cr > .cr-icon,
.radioo label input[type='radio'] + .cr > .cr-icon {
  transform: scale(3) rotateZ(-20deg);
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.checkboxx label input[type='checkbox']:checked + .cr > .cr-icon,
.radioo label input[type='radio']:checked + .cr > .cr-icon {
  transform: scale(1) rotateZ(0deg);
  opacity: 1;
}

.checkboxx label input[type='checkbox']:disabled + .cr,
.radioo label input[type='radio']:disabled + .cr {
  opacity: 0.5;
}

label.radio_label {
  display: flex;
  align-items: center;
}

.label_yazi {
  font-size: 17px;
  line-height: 20px;
  flex: 1;
}

.header_language a {
  // color: #000;
  font-weight: 700;
}

.header_language a i {
  color: #000;
}

.header_language {
  position: relative;
}

.language_drop {
  position: absolute;
  top: 100%;
  left: 0;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  transition: 200ms all;
  -webkit-transition: 200ms all;
  -moz-transition: 200ms all;
  -ms-transition: 200ms all;
  -o-transition: 200ms all;
  width: 100px;
  background: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transform: translate(0, -20px);
  -webkit-transform: translate(0, -20px);
  -moz-transform: translate(0, -20px);
  -ms-transform: translate(0, -20px);
  -o-transform: translate(0, -20px);
}

.header_language:focus-within .language_drop {
  opacity: 1;
  visibility: visible;
  z-index: 5;
  transform: translate(0, 10px);
  -webkit-transform: translate(0, 10px);
  -moz-transform: translate(0, 10px);
  -ms-transform: translate(0, 10px);
  -o-transform: translate(0, 10px);
}

.header_top a:focus-within,
.header_top a:focus-within i {
  color: #f15a24;
}

.header_signin.header_login {
  position: relative;
}

.profil_menu_drop {
  position: absolute;
  top: 100%;
  right: 0;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  transition: 200ms all;
  -webkit-transition: 200ms all;
  -moz-transition: 200ms all;
  -ms-transition: 200ms all;
  -o-transition: 200ms all;
  width: 260px;
  background: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transform: translate(0, -20px);
  -webkit-transform: translate(0, -20px);
  -moz-transform: translate(0, -20px);
  -ms-transform: translate(0, -20px);
  -o-transform: translate(0, -20px);
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.header_login:focus-within .profil_menu_drop {
  opacity: 1;
  visibility: visible;
  z-index: 5;
  transform: translate(0, 10px);
  -webkit-transform: translate(0, 10px);
  -moz-transform: translate(0, 10px);
  -ms-transform: translate(0, 10px);
  -o-transform: translate(0, 10px);
}

.profil_menu_drop_show {
  opacity: 1;
  visibility: visible;
  z-index: 5;
  transform: translate(0, 10px);
  -webkit-transform: translate(0, 10px);
  -moz-transform: translate(0, 10px);
  -ms-transform: translate(0, 10px);
  -o-transform: translate(0, 10px);
}

.app_menu_name_icon {
  width: 60px;
  height: 60px;
  display: flex;
  border: 2px solid #f15a24;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-shadow: #f7931e 2px 0 0px, #f7931e 1.75517px 0.958851px 0px, #f7931e 1.0806px 1.68294px 0px, #f7931e 0.141474px 1.99499px 0px,
    #f7931e -0.832294px 1.81859px 0px, #f7931e -1.60229px 1.19694px 0px, #f7931e -1.97999px 0.28224px 0px, #f7931e -1.87291px -0.701566px 0px,
    #f7931e -1.30729px -1.51361px 0px, #f7931e -0.421592px -1.95506px 0px, #f7931e 0.567324px -1.91785px 0px, #f7931e 1.41734px -1.41108px 0px,
    #f7931e 1.92034px -0.558831px 0;
}

.rows.profil_menu_drop_name {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
}

.rows.profil_menu_drop_name .app_menu_name_icon {
  margin-right: 6px;
}

nav.profildrop_menu {
  margin-top: 13px;
  float: left;
  width: 100%;
  padding-top: 13px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding-bottom: 13px;
  margin-bottom: 13px;
}

nav.profildrop_menu ul li a {
  display: inline-block;
  padding: 4px 0;
}

.header_right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

header.header {
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.06);
  position: relative;
}

header.header:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 49px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.06);
  content: '';
  z-index: 1;
}

.login_links a {
  display: flex;
  align-items: center;
}

.login_links a:first-child {
  margin-right: 20px;
}

.login_links {
  display: flex;
  align-items: center;
}

.login_links a img {
  display: block;
  margin-right: 5px;
}

header.header:before {
  content: '';
  z-index: 2;
  top: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
  background: linear-gradient(90deg, #fff 41.88%, rgba(255, 255, 255, 0) 77.95%);
}

.logo {
  position: relative;
  z-index: 4;
}

.header_top {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  position: relative;
  z-index: 6;
}

.header_top > div {
  margin-left: 20px;
}

.header_top > div:first-child {
  margin-left: 0;
}

.header_top a {
  font-weight: 700;
  font-size: 16px;
}

.header_top a i {
  font-size: 17px;
}

.header_motto h1 {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
}

.header_motto {
  margin-top: 20px;
  padding-top: 1px;
  padding-bottom: 14px;
  position: relative;
  z-index: 5;
}

section.home_hero {
  height: 84vh;
  position: relative;
  background-size: cover;
}

section.home_hero:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  content: '';
  background: linear-gradient(270deg, rgba(0, 0, 0, 0) 14.61%, #000 88.54%);
}

section.insurance:after {
  background: none;
}

section.home_hero section.section {
  height: 100%;
}

.hero_logos {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
}

.hero_logos_box {
  flex: 1;
  text-align: center;
  position: relative;
}

.hero_logos_box:nth-child(2):before {
  position: absolute;
  top: 0;
  bottom: 0;
  content: '';
  height: 70%;
  width: 1px;
  background-color: #fff;
  margin: auto;
  left: 0;
}

.hero_logos_box:nth-child(2):after {
  position: absolute;
  top: 0;
  bottom: 0;
  content: '';
  height: 70%;
  width: 1px;
  background-color: #fff;
  margin: auto;
  right: 0;
}

.home_transfer_tab {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  height: 50%;
  position: relative;
  z-index: 8;
}

.home_transfer_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
}

.siteform_row {
  width: 100%;
  height: 60px;
  float: left;
  background-color: #f1f2f7;
  border-radius: 5px;
  padding: 5px 10px;
  position: relative;
  margin-bottom: 15px;
}

.site_input_title {
  font-size: 14px;
  font-weight: 700;
  float: left;
  width: 100%;
  line-height: 17px;
}

.show ul.dropdown-menu.inner {
  display: inline-block;
  padding: 0 10px !important;
  width: 91px;
  padding-bottom: 0 !important;
}

.show ul.dropdown-menu.inner li {
  padding: 6px 0;
  font-weight: 700;
}

.btn.focus,
.btn:focus {
  box-shadow: none !important;
}

.siteform_row input[type='text'] {
  padding: 0;
  text-align: left;
  font-size: 16px;
  width: 100%;
  float: left;
  padding: 3px;
}

.site_input {
  float: left;
  width: 100%;
}

.site_select {
  position: relative;
  float: left;
  width: 100%;
}

.home_transfer_content .site_select > ion-item {
  position: relative;
  height: 23px !important;
  display: flex;
  align-items: center;
  padding: 0 0 0 22px !important;
}

.home_transfer_content .site_select > ion-list {
  position: relative;
  height: 23px !important;
  display: flex;
  align-items: center;
  padding: 0 0 0 22px !important;
}

.home_transfer_content .site_select::after {
  content: '\f1ab';
  font-family: material-design-iconic-font;
  left: 5px;
  position: absolute;
  top: 0;
  z-index: 999;
  color: #505050;
  font-size: 21px;
}

.site_select > ion-item {
  position: relative;
  height: 23px !important;
  display: flex;
  align-items: center;
  padding: 0 !important;
}

.site_select > ion-list {
  position: relative;
  height: 23px !important;
  display: flex;
  align-items: center;
  padding: 0 !important;
}

.site_select_to::after {
  content: '\f162';
}

.site_select_duration::after {
  content: '\f337';
  left: 0px;
}

.site_dateselect {
  float: left;
  position: relative;
  width: 100%;
}

select.select-form {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
  display: inline-block;
  font: inherit;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc0, #ccc0);
  background-position: calc(100% - 20px) calc(1em + -10px), calc(100% - 15px) calc(1em + -10px), calc(100% - 2.5em) 0em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  cursor: pointer;
  height: auto;
  border: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
}

select.select-form:focus-within {
  outline: 0;
  border: 0;
}

select.select-form:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.site_dateselect > p-calendar {
  text-align: left;
  padding-left: 25px !important;
  --background: transparent;
}

.p-inputtext {
  background-color: transparent;
  background: transparent;
  border: 0;
  font-weight: 700;
  padding: 0 3px !important;
}

.site_dateselect::after {
  content: '\f32e';
  font-family: material-design-iconic-font;
  left: 2px;
  position: absolute;
  top: 0;
  z-index: 1063;
  color: #505050;
  font-size: 20px;
}

.site_select.site_select_duration {
  padding-left: 27px;
}

.site_timeselect {
  float: left;
  position: relative;
  width: 100%;
}

.site_timeselect > p-calendar {
  text-align: left;
  padding-left: 25px !important;
  --background: transparent;
}

.p-inputtext {
  background-color: transparent;
  background: transparent;
  border: 0;
  font-weight: 700;
  padding: 0 3px !important;
}

.site_timeselect::after {
  content: '\f337';
  font-family: material-design-iconic-font;
  left: 2px;
  position: absolute;
  top: 0;
  z-index: 1063;
  color: #505050;
  font-size: 20px;
}

.home_transfer_box {
  flex: 1;
}

.home_transfer_box span.site_input_title {
  padding-left: 26px;
}

.sitebuttonCanceled {
  background: linear-gradient(90deg, red 0%, red 100%), red;
  border-radius: 5px;
  width: 100%;
  padding: 19px 0;
  color: #fff;
  transition: background 1s ease-out;
  position: relative;
  z-index: 3;
  overflow: hidden;
  height: auto;
  --background: transparent;
  --box-shadow: 0;
}

.sitebuttonCanceled::before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-90deg, red 0%, red, 100%), red;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: transform 1.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 1.2s;
  -webkit-transform-origin: right center;
  transform-origin: right center;
  z-index: -1;
  box-sizing: border-box;
  display: block;
  -moz-transition: transform 1.2s;
  -ms-transition: transform 1.2s;
  -o-transition: transform 1.2s;
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -o-transform: scaleX(0);
  border-radius: 5px;
}

.sitebutton {
  background: linear-gradient(90deg, #ff512f 0%, #f09819 100%), #f15a24;
  border-radius: 5px;
  width: 100%;
  padding: 19px 0;
  color: #fff;
  transition: background 1s ease-out;
  position: relative;
  z-index: 3;
  overflow: hidden;
  height: auto;
  --background: transparent;
  --box-shadow: 0;
}

.sitebutton::before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-90deg, #ff512f 0%, #ff512f 100%), #ff512f;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: transform 1.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 1.2s;
  -webkit-transform-origin: right center;
  transform-origin: right center;
  z-index: -1;
  box-sizing: border-box;
  display: block;
  -moz-transition: transform 1.2s;
  -ms-transition: transform 1.2s;
  -o-transition: transform 1.2s;
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -o-transform: scaleX(0);
  border-radius: 5px;
}

.sitebutton:hover:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: left center;
  transform-origin: left center;
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -o-transform: scaleX(1);
  border-radius: 5px;
}

.sitebutton:hover {
  color: #000;
}

ion-button.sitebutton {
  padding: 0;
  height: 50px;
}

.sitebutton_turquoise {
  background: linear-gradient(90deg, #01a0ac 0%, #06d5e4 100%);
  border-radius: 5px;
}

.sitebutton_turquoise::before {
  background: linear-gradient(-90deg, #01a0ac 0%, #01a0ac 100%);
  border-radius: 5px;
}

.logo img {
  display: block;
}

.signin_language {
  width: 90px;
}

.radio_label2 {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 11px 44px rgba(0, 0, 0, 0.14);
  border-radius: 30px;
  position: relative;
  padding: 40px 10px;
}

.checkboxx2 label input[type='checkbox'],
.radioo2 label input[type='radio'] {
  display: none;
}

.vehicle_border {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 7px solid #f15a24;
  border-radius: 30px;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

.checkboxx2 label input[type='checkbox']:checked + .vehicle_border,
.radioo2 label input[type='radio']:checked + .vehicle_border {
  opacity: 1;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

.checkboxx2 label input[type='checkbox']:disabled + .vehicle_border,
.radioo2 label input[type='radio']:disabled + .vehicle_border {
  opacity: 0.5;
}

section.contentpage header.sitetittle {
  padding-top: 0;
}

.vehicle_price {
  font-weight: 700;
  font-size: 27px;
  font-family: 'AveNext', 'Trebuchet MS', sans-serif !important;
}

.vehicle_title h3 {
  font-size: 23px;
}

.vehicle_title h5 {
  font-size: 18px;
  padding: 10px 0;
}

.vehicle_capasity {
  font-size: 18px;
  column-gap: 20px;
}

header.content_header {
  float: left;
  width: 100%;
  background-color: #ebebeb;
}

nav.content_header_links {
  float: left;
  width: 100%;
}

nav.content_header_links ul {
  display: flex;
}

nav.content_header_links ul li a {
  padding: 18px 43px;
  padding-left: 0;
  display: inline-block;
  font-size: 18px;
}

header.content_header_bottom {
  float: left;
  width: 100%;
  background-color: #000;
  color: #fff;
  font-size: 14px;
}

header.content_header_bottom a {
  color: #fff;
}

.content_header_bottom_details {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.transfer_distance {
  padding: 5px 10px;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  flex: 1;
  text-align: center;
}

.transfer_pickup {
  display: flex;
  align-items: center;
  padding-right: 33px;
}

.transfer_date {
  flex: 1;
  padding-left: 28px;
}

.transfer_pickup i {
  margin: 0 31px;
}

.site_textarea {
  float: left;
  width: 100%;
}

.site_textarea textarea {
  float: left;
  width: 100%;
  background-color: transparent;
  border: 0;
}

.site_textarea ion-textarea {
  float: left;
  width: 100%;
  background-color: transparent;
  border: 0;
}

.siteboxr {
  float: left;
  width: 100%;
  background: #fff;
  box-shadow: 0 11px 44px rgba(0, 0, 0, 0.14);
  border-radius: 15px;
  padding: 25px;
  overflow: hidden;
  min-height: 100%;
}

.siteboxr.maps {
  height: 100%;
  padding: 0;
}

.siteboxr h3 {
  font-size: 23px;
  font-weight: 700;
}

.siteboxr_row {
  padding-top: 0;
  margin-top: 20px;
}

.siteboxr_border {
  border-top: 1px solid #b0b2b7;
  padding-top: 20px;
}

.siteboxr .sitelink {
  font-weight: 700;
}

.checkout_credidcard_info_cardno {
  margin-left: 20px;
}

.checkout_credidcard_info_logo img {
  display: block;
}

span.siteboxr_row_title {
  float: left;
  width: 100%;
  display: block;
  color: #a5a5a5;
  font-size: 14px;
}

.siteboxr_row h4 {
  font-weight: 700;
  font-size: 18px;
}

.checkout_vehice_details_img h6 {
  font-size: 14px;
  color: #999;
  margin-top: 15px;
}

.modal-title {
  font-weight: 700;
  font-size: 24px;
  color: #f15a24;
}

.checkout_addvoucher button {
  padding: 14px 30px;
}

/******************************************************
İçerik
******************************************************/
section.contentpage {
  padding: 40px 0;
  position: relative;
  min-height: 495px;
}

.content_signinbox {
  max-width: 630px;
  width: 90%;
  padding: 60px 20px;
  box-shadow: 0 24px 44px rgba(0, 0, 0, 0.14);
  border-radius: 15px;
  display: flex;
  text-align: center;
  flex-direction: column;
}

.signin_input {
  display: flex;
  align-items: center;
  padding: 10px;
}

.site_input input {
  background-color: transparent;
  border: 0;
  color: #000;
}

.siteform_row.signin_input input {
  font-weight: 700;
}

.siteform_row.signin_input .dropdown-toggle::after {
  display: none;
}

.signin_language button.btn.dropdown-toggle.bs-placeholder.btn-default {
  display: flex;
  align-items: center;
}

.rows.siteform_button {
  margin-top: 20px;
}

.content_signinbox h2 {
  font-size: 44px;
  font-weight: 700;
}

.content_signinbox h4 {
  font-size: 16px;
  margin-bottom: 60px;
  margin-top: 20px;
}

.personalinformation .signin_input {
  padding: 0;
}

.personalinformation button.btn.dropdown-toggle.bs-placeholder.btn-default {
  padding: 0;
}

.Vehicles_list {
  float: left;
  width: 100%;
}

.Vehicles_list ul {
  display: flex;
  column-gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 20px;
}

.Vehicles_list ul li {
  width: 49%;
  position: relative;
}

.Vehicles_list ul li .siteboxr {
  display: flex;
  flex-wrap: wrap;
}

.Vehicles_list_img {
  float: left;
  width: 32%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px dashed #ccc;
}

.Vehicles_list_details {
  width: 50%;
  margin-left: 5%;
}

.Vehicles_list_button {
  position: absolute;
  bottom: 14px;
  right: 19px;
  z-index: 10;
  display: flex;
  align-items: center;
}

.Vehicles_list_button a {
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #eee;
  color: #fff;
  font-size: 15px;
  margin-left: 5px;
}

a.editbg {
  background: linear-gradient(90deg, #01a0ac 0%, #06d5e4 100%);
}

a.deletebg {
  background: linear-gradient(90deg, #f00000 0%, #c62118 100%), #c4c4c4;
}

a.editbg i {
  transform: translate(2px, 0px);
}

a.deletebg i {
  transform: translate(1px, 0px);
}

.Vehicles_list_details {
  padding-bottom: 35px;
}

.invite_top svg {
  width: 100%;
  height: 400px;
}

@keyframes appear {
  0% {
    transform: scale(0.8);
    transform-origin: center center;
    opacity: 0;
  }

  95% {
    transform: scale(3.05);
    transform-origin: center center;
    opacity: 0.5;
    -webkit-transform: scale(3.05);
    -moz-transform: scale(3.05);
    -ms-transform: scale(3.05);
    -o-transform: scale(3.05);
  }

  100% {
    transform: scale(4);
    transform-origin: center center;
    opacity: 1;
    -webkit-transform: scale(4);
    -moz-transform: scale(4);
    -ms-transform: scale(4);
    -o-transform: scale(4);
  }
}

@keyframes vibrate {
  0% {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
  }

  10% {
    transform: rotate(5deg);
    transform-origin: 50% 50%;
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
  }

  20% {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
  }

  30% {
    transform: rotate(5deg);
    transform-origin: 50% 50%;
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
  }

  40% {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
  }

  100% {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  0% {
    opacity: 1;
  }
}

@keyframes float {
  0% {
    transform: translatey(0%);
  }

  40% {
    transform: translatey(2%);
  }

  100% {
    transform: translatey(0%);
  }
}

@keyframes scale {
  0% {
    transform: scale(0.98);
    transform-origin: center center;
  }

  50% {
    transform: scale(1);
    transform-origin: center center;
  }

  100% {
    transform: scale(0.98);
    transform-origin: center center;
  }
}

@keyframes leak {
  0% {
    transform: translatey(0%);
  }

  40% {
    transform: translatey(1%);
  }

  100% {
    transform: translatey(0%);
  }
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
  }

  2% {
    transform: rotate(4deg);
    transform-origin: 50% 50%;
    -webkit-transform: rotate(4deg);
    -moz-transform: rotate(4deg);
    -ms-transform: rotate(4deg);
    -o-transform: rotate(4deg);
  }

  4% {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
  }

  6% {
    transform: rotate(4deg);
    transform-origin: 50% 50%;
    -webkit-transform: rotate(4deg);
    -moz-transform: rotate(4deg);
    -ms-transform: rotate(4deg);
    -o-transform: rotate(4deg);
  }

  8% {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
  }

  10% {
    transform: rotate(4deg);
    transform-origin: 50% 50%;
    -webkit-transform: rotate(4deg);
    -moz-transform: rotate(4deg);
    -ms-transform: rotate(4deg);
    -o-transform: rotate(4deg);
  }

  12% {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
  }

  100% {
    transform: rotate(0deg);
    transform-origin: 50% 50%;
  }
}

@keyframes beat {
  0% {
    transform: scale(0.98);
    transform-origin: center left;
  }

  20% {
    transform: scale(1);
    transform-origin: center left;
  }

  100% {
    transform: scale(0.98);
    transform-origin: center left;
  }
}

@keyframes pop {
  0%,
  100% {
    transform: opacity(0);
    transform: scale(0);
    transform-origin: center center;
  }

  2% {
    transform: opacity(1);
    transform: scale(1);
    transform-origin: center center;
  }

  10% {
    transform: translatey(1%);
  }

  19% {
    transform: translatey(0%);
  }

  20% {
    transform: translatey(0.5%);
  }

  29% {
    transform: translatey(0%);
  }

  30% {
    transform: translatey(1%);
  }

  39% {
    transform: translatey(0%);
  }

  40% {
    transform: opacity(1);
    transform: scale(1);
    transform-origin: 50% 50%;
  }

  42% {
    transform: opacity(0);
    transform: scale(0);
    transform-origin: 50% 50%;
  }
}

@keyframes oscillate {
  0% {
    transform: rotate(0deg);
    transform-origin: top center;
  }

  50% {
    transform: rotate(-1deg);
    transform-origin: top center;
  }

  100% {
    transform: rotate(0deg);
    transform-origin: top center;
  }
}

@keyframes stretch-y {
  0% {
    transform: rotatex(2deg);
    transform-origin: 50% 100%;
  }

  50% {
    transform: rotatex(0deg);
    transform-origin: 50% 100%;
  }

  100% {
    transform: rotatex(2deg);
    transform-origin: 50% 100%;
  }
}

@keyframes shine {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }

  0% {
    opacity: 1;
  }
}

@keyframes movex-short {
  0% {
    transform: translatex(0%);
  }

  50% {
    transform: translatex(2%);
    -webkit-transform: translatex(2%);
    -moz-transform: translatex(2%);
    -ms-transform: translatex(2%);
    -o-transform: translatex(2%);
  }

  100% {
    transform: translatex(0%);
  }
}

@keyframes fly2 {
  0% {
    transform: rotate(0deg);
    transform-origin: 0 60%;
  }

  50% {
    transform: rotate(5deg);
    transform-origin: 0 60%;
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
  }

  100% {
    transform: rotate(0deg);
    transform-origin: 0 60%;
  }
}

@keyframes rotatesun {
  from {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
}

.home-gift {
  animation: appear 0.2s ease-in-out, movex-short 4s infinite;
  -webkit-animation: appear 0.2s ease-in-out, movex-short 4s infinite;
}

.home-gift2 {
  animation: appear 0.2s ease-in-out, movex-short 8s infinite;
  -webkit-animation: appear 0.2s ease-in-out, movex-short 8s infinite;
}

#home-cloud2 {
  animation: appear 0.2s ease-in-out;
  animation-delay: 0.3s;
  animation-fill-mode: both;
  -webkit-animation: appear 0.2s ease-in-out;
}

#home-wave {
  animation: appear 0.2s ease-in-out;
  animation-delay: 1s;
  animation-fill-mode: both;
  -webkit-animation: appear 0.2s ease-in-out;
}

.home-sun {
  animation: fly2 3.2s linear infinite;
  -webkit-animation: fly2 3.2s linear infinite;
}

.home-plane1 {
  animation: fly2 3.2s linear infinite;
  -webkit-animation: fly2 3.2s linear infinite;
}

.home-plane3 {
  animation: fly2 3.2s linear infinite;
  -webkit-animation: fly2 3.2s linear infinite;
}

.home-wholephone {
  animation: appear 0.2s ease-in-out, vibrate 1s infinite;
  animation-delay: 0.2s;
  animation-fill-mode: both;
  -webkit-animation: appear 0.2s ease-in-out, vibrate 1s infinite;
}

.home-dashes {
  animation: appear 0.2s ease-in-out, fade 3s infinite;
  -webkit-animation: appear 0.2s ease-in-out, fade 3s infinite;
}

.home-dashes2 {
  animation: appear 0.2s ease-in-out, fade 7s infinite;
  -webkit-animation: appear 0.2s ease-in-out, fade 7s infinite;
}

.home-dashes3 {
  animation: appear 0.2s ease-in-out, fade 5s infinite;
  -webkit-animation: appear 0.2s ease-in-out, fade 5s infinite;
}

#home-wholeskate {
  animation: appear 0.2s ease-in-out, movex-short 2s infinite;
  animation-delay: 1s;
  animation-fill-mode: both;
  -webkit-animation: appear 0.2s ease-in-out, movex-short 2s infinite;
}

.st0 {
  fill: #ffed00;
}

.st1 {
  fill: #ffb600;
}

.st2 {
  fill: none;
}

.st3 {
  fill: #ffed00;
}

.st4 {
  fill: none;
  stroke: #e45c0f;
  stroke-width: 2.5;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st5 {
  fill: #ffed00;
}

.st6 {
  fill: #e0a920;
}

.invitebottom h2 {
  font-size: 44px;
  font-weight: 700;
}

.biling_tab_row_border {
  border-bottom: 2px solid #ccc;
}

.myservices .tabs nav a:before {
  height: 2px !important;
  background-color: #000;
}

.myservices .tabs nav a:after {
  height: 2px !important;
  bottom: -1px;
  display: none;
}

.myservices .tabs nav a {
  height: 104%;
  color: #b5b5b5;
}

.myservices .tabs nav .tab-current a {
  color: #000;
}

.help_toplink {
  float: left;
  width: 100%;
  margin-bottom: 40px;
  position: relative;
}

.helplink_details {
  width: 100%;
  display: flex;
  float: left;
  justify-content: space-between;
  padding: 16px;
  align-items: center;
  font-weight: 700;
  overflow: hidden;
}

.help_toplink a {
  border: 1px solid #b0b2b7;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.help_toplink_livesupport {
  float: left;
  width: 100%;
  margin-bottom: 40px;
}

.help_toplink_livesupport a {
  background-color: #f15a24;
  color: #fff;
  border-radius: 10px;
  border: 1px solid #b0b2b7;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.help_links {
  float: left;
  width: 100%;
  border: 1px solid #b0b2b7;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  overflow: hidden;
  padding: 11px 16px;
  background-color: #fff;
}

.help_links a.helplink_details.sitelink span {
  padding-right: 20px;
}

.content_header_help {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;
}

.content_header_links {
  display: flex;
  align-items: center;
}

.content_header_links i {
  margin: 0 20px;
}

.content_header_back i {
  margin-right: 18px;
}

.helpdetails_text {
  float: left;
  width: 100%;
  border: 1px solid #b0b2b7;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  overflow: hidden;
  padding: 20px;
  background-color: #fff;
}

.help_links a.helplink_details.sitelink {
  padding: 16px 0;
  border-bottom: 1px solid #dcdcdc;
}

.helpdetails_search {
  background-color: #fff;
  margin-bottom: 26px;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #b0b2b7;
  display: flex;
  justify-content: space-between;
}

.helpdetails_search input[type='text'] {
  background-color: transparent;
  border: 0;
  font-weight: 700;
}

.helpdetails_search button {
  font-size: 20px;
}

.help_links a:last-child {
  border-bottom: 0 !important;
}

.helplink_details::before {
  top: 91%;
  height: 5px;
}

.site_select2 {
  float: left;
  width: 100%;
  position: relative;
}

.site_select2 .dropdown-toggle::after {
  position: absolute;
  top: 11px;
  right: 4px;
}

.Promotions_list ul {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
}

.Promotions_list ul li {
  width: 32%;
  display: flex;
  flex-direction: column;
}

.Promotions_img {
  margin-bottom: 20px;
}

.Promotions_img img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 10px;
}

.rows.Promotions_title {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 10px;
}

.rows.Promotions_details {
  margin-bottom: 20px;
}

.Promotions_code_apply {
  float: left;
  width: 100%;
  padding: 10px;
  border: 1px solid #acacac;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Promotions_input {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.Promotions_input span {
  font-size: 14px;
}

.Promotions_input input[type='text'] {
  background-color: transparent;
  border: 0;
  width: 100%;
}

.Promotions_buttno button {
  font-size: 15px;
  font-weight: 700;
  padding: 7px 29px;
  box-shadow: 0 4px 23px rgba(0, 0, 0, 0.15);
  border-radius: 50px;
}

.helpdetails {
  padding: 40px;
  background-color: #ebeef1;
}

.footer {
  float: left;
  width: 100%;
  position: relative;
  background-color: #000;
  padding: 60px 0;

  &.alt {
    // padding: 40px 640px;
    height: 100%;
    border-top: 1px #cd2d08 solid;
    margin-top: 1px;
  }
}

.footer_top {
  display: flex;
  justify-content: space-between;
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid #323232;
  align-items: center;
}

.footer_top a {
  color: #fff;
  font-weight: 700;
}

.f_logo img {
  display: block;
}

.footer_app_icon {
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid #323232;
}

.footer_app_icon a {
  margin-right: 12px;
}

.footer_bottom {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #fff;
}

.footer_bottom a {
  color: #fff;
}

.checkout_row {
  position: relative;
}

.biling_tab_row .content-wrap section {
  height: auto;
  position: relative;
  float: left;
  width: 100%;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  display: none;
}

.biling_tab_row .content-wrap section.content-current {
  display: block;
}

.biling_tab_row .content-wrap {
  height: auto;
  margin-top: 0;
}

.search-results-div {
  background-color: #fff;
  position: absolute;
  z-index: 999;
  width: 400px;
  max-height: 300px;
  overflow: auto;
  cursor: pointer;
}

.search-results-div > ion-item {
  --color-hover: primary;
}

.error {
  display: block;
  position: absolute;
  top: 30px;
  right: 5px;
  padding: 0;
  color: red;
  font-size: 10px;
  padding-left: 0;
}

.home_transfer_content .error {
  top: 7px;
}

.app_main {
  min-height: 500px;
}

.modal-wrapper .paymentpage ion-col,
.modal-wrapper .billingpage ion-col {
  width: 100% !important;
  flex: auto !important;
  max-width: none !important;
}

.site_select2 select {
  background: transparent;
  border: 0;
  font-weight: 700;
}

.site_select span.error {
  top: 9px;
  right: 25px;
}

.checkout {
  float: left;
  width: 100%;
}

.checkout .siteform_row {
  margin-bottom: 0;
}

.home_transfer_content .siteform_row {
  margin-bottom: 0;
}

.footer_links span {
  margin: 0 8px;
}

.vehicle_bag {
  margin-left: 10px;
}

.text-center {
  text-align: center;
}

.modal-wrapper .sitetittle {
  display: none;
}

.mobil_menu_close {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  position: absolute;
  top: 0px;
  transform: translate(0, 20px);
  -webkit-transform: translate(0, 20px);
  -moz-transform: translate(0, 20px);
  -ms-transform: translate(0, 20px);
  -o-transform: translate(0, 20px);
  right: 12px;
  font-size: 25px;
  color: #ff0000;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  opacity: 0;
  z-index: -1;
}

// fonts
@font-face {
  font-family: 'AveNext';
  src: url('theme/fonts/AveNext-Demi.eot');
  src: url('theme/fonts/AveNext-Demi.eot?#iefix') format('embedded-opentype'), url('theme/fonts/AveNext-Demi.woff2') format('woff2'),
    url('theme/fonts/AveNext-Demi.woff') format('woff'), url('theme/fonts/AveNext-Demi.svg#AveNext-Demi') format('svg');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-02DB;
}

@font-face {
  font-family: 'AveNext';
  src: url('theme/fonts/AveNext-Regular.eot');
  src: url('theme/fonts/AveNext-Regular.eot?#iefix') format('embedded-opentype'), url('theme/fonts/AveNext-Regular.woff2') format('woff2'),
    url('theme/fonts/AveNext-Regular.woff') format('woff'), url('theme/fonts/AveNext-Regular.svg#AveNext-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-0237;
}

// gcrid.css
.section {
  clear: both;
  margin: 0 auto;
  padding: 0 0.6%;
  width: 95%;
  max-width: 1440px;
}

.group:before,
.group:after {
  content: '';
  display: table;
}

.group:after {
  clear: both;
}

.group {
  zoom: 1;
}

.col {
  display: block;
  float: left;
  padding: 1% 1% 1% 1.6%;
  min-height: 1px;
}

.col:first-child {
  padding-left: 0;
}

.colfirst {
  padding-left: 0;
}

.collast {
  padding-right: 0;
}

.col10 {
  display: block;
  float: left;
  padding: 1% 0 1% 1.6%;
  min-height: 1px;
}

.col10:first-child {
  padding-left: 0;
}

.col10first {
  padding-left: 0;
}

.col10last {
  padding-right: 0;
}

@media only screen and (max-width: 768px) {
  ion-modal.fixed-height-modal {
    --height: 100%;
    --max-height: 100%;
    --min-height: 100%;
  }
  .col {
    padding: 1% 0;
  }

  .col10 {
    padding: 1% 0;
  }
}

.col50 {
  width: 50%;
}

.col25 {
  width: 25%;
}

.col33 {
  width: 33.333%;
}

.col12 {
  width: 100%;
}

.col11 {
  width: 91.66666667%;
}

.col10 {
  width: 83.33333333%;
}

.col9 {
  width: 75%;
}

.col8 {
  width: 66.66666667%;
}

.col7 {
  width: 58.33333333%;
}

.col6 {
  width: 50%;
}

.col5 {
  width: 41.66666667%;
}

.col4 {
  width: 33.33333333%;
}

.col3 {
  width: 25%;
}

.col2 {
  width: 16.66666667%;
}

.col1 {
  width: 8.33333333%;
}

.col10_10 {
  width: 100%;
}

.col10_9 {
  width: 90%;
}

.col10_8 {
  width: 80%;
}

.col10_7 {
  width: 70%;
}

.col10_6 {
  width: 60%;
}

.col10_5 {
  width: 50%;
}

.col10_4 {
  width: 40%;
}

.col10_3 {
  width: 30%;
}

.col10_2 {
  width: 20%;
}

.col10_1 {
  width: 10%;
}

@media only screen and (max-width: 768px) {
  .section {
    width: 95%;
  }

  .col12 {
    width: 100%;
  }

  .col11 {
    width: 100%;
  }

  .col10 {
    width: 100%;
  }

  .col9 {
    width: 100%;
  }

  .col8 {
    width: 100%;
  }

  .col7 {
    width: 100%;
  }

  .col6 {
    width: 100%;
  }

  .col5 {
    width: 100%;
  }

  .col4 {
    width: 100%;
  }

  .col3 {
    width: 100%;
  }

  .col2 {
    width: 100%;
  }

  .col1 {
    width: 100%;
  }

  .col50 {
    width: 100%;
  }

  .col25 {
    width: 100%;
  }

  .col33 {
    width: 100%;
  }

  .col10_10 {
    width: 100%;
  }

  .col10_9 {
    width: 100%;
  }

  .col10_8 {
    width: 100%;
  }

  .col10_7 {
    width: 100%;
  }

  .col10_6 {
    width: 100%;
  }

  .col10_5 {
    width: 100%;
  }

  .col10_4 {
    width: 100%;
  }

  .col10_3 {
    width: 100%;
  }

  .col10_2 {
    width: 100%;
  }

  .col10_1 {
    width: 100%;
  }
}

a:focus {
  outline: none;
}

a:hover {
  text-decoration: none;
}

button {
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
}

button:focus {
  outline: none;
}

iframe {
  border: none;
}

.fs-1 {
  font-size: 1px;
}

.fs-2 {
  font-size: 2px;
}

.fs-3 {
  font-size: 3px;
}

.fs-4 {
  font-size: 4px;
}

.fs-5 {
  font-size: 5px;
}

.fs-6 {
  font-size: 6px;
}

.fs-7 {
  font-size: 7px;
}

.fs-8 {
  font-size: 8px;
}

.fs-9 {
  font-size: 9px;
}

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.fs-19 {
  font-size: 19px;
}

.fs-20 {
  font-size: 20px;
}

.fs-21 {
  font-size: 21px;
}

.fs-22 {
  font-size: 22px;
}

.fs-23 {
  font-size: 23px;
}

.fs-24 {
  font-size: 24px;
}

.fs-25 {
  font-size: 25px;
}

.fs-26 {
  font-size: 26px;
}

.fs-27 {
  font-size: 27px;
}

.fs-28 {
  font-size: 28px;
}

.fs-29 {
  font-size: 29px;
}

.fs-30 {
  font-size: 30px;
}

.fs-31 {
  font-size: 31px;
}

.fs-32 {
  font-size: 32px;
}

.fs-33 {
  font-size: 33px;
}

.fs-34 {
  font-size: 34px;
}

.fs-35 {
  font-size: 35px;
}

.fs-36 {
  font-size: 36px;
}

.fs-37 {
  font-size: 37px;
}

.fs-38 {
  font-size: 38px;
}

.fs-39 {
  font-size: 39px;
}

.fs-40 {
  font-size: 40px;
}

.fs-41 {
  font-size: 41px;
}

.fs-42 {
  font-size: 42px;
}

.fs-43 {
  font-size: 43px;
}

.fs-44 {
  font-size: 44px;
}

.fs-45 {
  font-size: 45px;
}

.fs-46 {
  font-size: 46px;
}

.fs-47 {
  font-size: 47px;
}

.fs-48 {
  font-size: 48px;
}

.fs-49 {
  font-size: 49px;
}

.fs-50 {
  font-size: 50px;
}

.fs-51 {
  font-size: 51px;
}

.fs-52 {
  font-size: 52px;
}

.fs-53 {
  font-size: 53px;
}

.fs-54 {
  font-size: 54px;
}

.fs-55 {
  font-size: 55px;
}

.fs-56 {
  font-size: 56px;
}

.fs-57 {
  font-size: 57px;
}

.fs-58 {
  font-size: 58px;
}

.fs-59 {
  font-size: 59px;
}

.fs-60 {
  font-size: 60px;
}

.fs-61 {
  font-size: 61px;
}

.fs-62 {
  font-size: 62px;
}

.fs-63 {
  font-size: 63px;
}

.fs-64 {
  font-size: 64px;
}

.fs-65 {
  font-size: 65px;
}

.fs-66 {
  font-size: 66px;
}

.fs-67 {
  font-size: 67px;
}

.fs-68 {
  font-size: 68px;
}

.fs-69 {
  font-size: 69px;
}

.fs-70 {
  font-size: 70px;
}

.fs-71 {
  font-size: 71px;
}

.fs-72 {
  font-size: 72px;
}

.fs-73 {
  font-size: 73px;
}

.fs-74 {
  font-size: 74px;
}

.fs-75 {
  font-size: 75px;
}

.fs-76 {
  font-size: 76px;
}

.fs-77 {
  font-size: 77px;
}

.fs-78 {
  font-size: 78px;
}

.fs-79 {
  font-size: 79px;
}

.fs-80 {
  font-size: 80px;
}

.fs-81 {
  font-size: 81px;
}

.fs-82 {
  font-size: 82px;
}

.fs-83 {
  font-size: 83px;
}

.fs-84 {
  font-size: 84px;
}

.fs-85 {
  font-size: 85px;
}

.fs-86 {
  font-size: 86px;
}

.fs-87 {
  font-size: 87px;
}

.fs-88 {
  font-size: 88px;
}

.fs-89 {
  font-size: 89px;
}

.fs-90 {
  font-size: 90px;
}

.fs-91 {
  font-size: 91px;
}

.fs-92 {
  font-size: 92px;
}

.fs-93 {
  font-size: 93px;
}

.fs-94 {
  font-size: 94px;
}

.fs-95 {
  font-size: 95px;
}

.fs-96 {
  font-size: 96px;
}

.fs-97 {
  font-size: 97px;
}

.fs-98 {
  font-size: 98px;
}

.fs-99 {
  font-size: 99px;
}

.fs-100 {
  font-size: 100px;
}

.fs-101 {
  font-size: 101px;
}

.fs-102 {
  font-size: 102px;
}

.fs-103 {
  font-size: 103px;
}

.fs-104 {
  font-size: 104px;
}

.fs-105 {
  font-size: 105px;
}

.fs-106 {
  font-size: 106px;
}

.fs-107 {
  font-size: 107px;
}

.fs-108 {
  font-size: 108px;
}

.fs-109 {
  font-size: 109px;
}

.fs-110 {
  font-size: 110px;
}

.fs-111 {
  font-size: 111px;
}

.fs-112 {
  font-size: 112px;
}

.fs-113 {
  font-size: 113px;
}

.fs-114 {
  font-size: 114px;
}

.fs-115 {
  font-size: 115px;
}

.fs-116 {
  font-size: 116px;
}

.fs-117 {
  font-size: 117px;
}

.fs-118 {
  font-size: 118px;
}

.fs-119 {
  font-size: 119px;
}

.fs-120 {
  font-size: 120px;
}

.fs-121 {
  font-size: 121px;
}

.fs-122 {
  font-size: 122px;
}

.fs-123 {
  font-size: 123px;
}

.fs-124 {
  font-size: 124px;
}

.fs-125 {
  font-size: 125px;
}

.fs-126 {
  font-size: 126px;
}

.fs-127 {
  font-size: 127px;
}

.fs-128 {
  font-size: 128px;
}

.fs-129 {
  font-size: 129px;
}

.fs-130 {
  font-size: 130px;
}

.fs-131 {
  font-size: 131px;
}

.fs-132 {
  font-size: 132px;
}

.fs-133 {
  font-size: 133px;
}

.fs-134 {
  font-size: 134px;
}

.fs-135 {
  font-size: 135px;
}

.fs-136 {
  font-size: 136px;
}

.fs-137 {
  font-size: 137px;
}

.fs-138 {
  font-size: 138px;
}

.fs-139 {
  font-size: 139px;
}

.fs-140 {
  font-size: 140px;
}

.fs-141 {
  font-size: 141px;
}

.fs-142 {
  font-size: 142px;
}

.fs-143 {
  font-size: 143px;
}

.fs-144 {
  font-size: 144px;
}

.fs-145 {
  font-size: 145px;
}

.fs-146 {
  font-size: 146px;
}

.fs-147 {
  font-size: 147px;
}

.fs-148 {
  font-size: 148px;
}

.fs-149 {
  font-size: 149px;
}

.fs-150 {
  font-size: 150px;
}

.fs-151 {
  font-size: 151px;
}

.fs-152 {
  font-size: 152px;
}

.fs-153 {
  font-size: 153px;
}

.fs-154 {
  font-size: 154px;
}

.fs-155 {
  font-size: 155px;
}

.fs-156 {
  font-size: 156px;
}

.fs-157 {
  font-size: 157px;
}

.fs-158 {
  font-size: 158px;
}

.fs-159 {
  font-size: 159px;
}

.fs-160 {
  font-size: 160px;
}

.fs-161 {
  font-size: 161px;
}

.fs-162 {
  font-size: 162px;
}

.fs-163 {
  font-size: 163px;
}

.fs-164 {
  font-size: 164px;
}

.fs-165 {
  font-size: 165px;
}

.fs-166 {
  font-size: 166px;
}

.fs-167 {
  font-size: 167px;
}

.fs-168 {
  font-size: 168px;
}

.fs-169 {
  font-size: 169px;
}

.fs-170 {
  font-size: 170px;
}

.fs-171 {
  font-size: 171px;
}

.fs-172 {
  font-size: 172px;
}

.fs-173 {
  font-size: 173px;
}

.fs-174 {
  font-size: 174px;
}

.fs-175 {
  font-size: 175px;
}

.fs-176 {
  font-size: 176px;
}

.fs-177 {
  font-size: 177px;
}

.fs-178 {
  font-size: 178px;
}

.fs-179 {
  font-size: 179px;
}

.fs-180 {
  font-size: 180px;
}

.fs-181 {
  font-size: 181px;
}

.fs-182 {
  font-size: 182px;
}

.fs-183 {
  font-size: 183px;
}

.fs-184 {
  font-size: 184px;
}

.fs-185 {
  font-size: 185px;
}

.fs-186 {
  font-size: 186px;
}

.fs-187 {
  font-size: 187px;
}

.fs-188 {
  font-size: 188px;
}

.fs-189 {
  font-size: 189px;
}

.fs-190 {
  font-size: 190px;
}

.fs-191 {
  font-size: 191px;
}

.fs-192 {
  font-size: 192px;
}

.fs-193 {
  font-size: 193px;
}

.fs-194 {
  font-size: 194px;
}

.fs-195 {
  font-size: 195px;
}

.fs-196 {
  font-size: 196px;
}

.fs-197 {
  font-size: 197px;
}

.fs-198 {
  font-size: 198px;
}

.fs-199 {
  font-size: 199px;
}

.fs-200 {
  font-size: 200px;
}

.p-t-0 {
  padding-top: 0;
}

.p-t-1 {
  padding-top: 1px;
}

.p-t-2 {
  padding-top: 2px;
}

.p-t-3 {
  padding-top: 3px;
}

.p-t-4 {
  padding-top: 4px;
}

.p-t-5 {
  padding-top: 5px;
}

.p-t-6 {
  padding-top: 6px;
}

.p-t-7 {
  padding-top: 7px;
}

.p-t-8 {
  padding-top: 8px;
}

.p-t-9 {
  padding-top: 9px;
}

.p-t-10 {
  padding-top: 10px;
}

.p-t-11 {
  padding-top: 11px;
}

.p-t-12 {
  padding-top: 12px;
}

.p-t-13 {
  padding-top: 13px;
}

.p-t-14 {
  padding-top: 14px;
}

.p-t-15 {
  padding-top: 15px;
}

.p-t-16 {
  padding-top: 16px;
}

.p-t-17 {
  padding-top: 17px;
}

.p-t-18 {
  padding-top: 18px;
}

.p-t-19 {
  padding-top: 19px;
}

.p-t-20 {
  padding-top: 20px;
}

.p-t-21 {
  padding-top: 21px;
}

.p-t-22 {
  padding-top: 22px;
}

.p-t-23 {
  padding-top: 23px;
}

.p-t-24 {
  padding-top: 24px;
}

.p-t-25 {
  padding-top: 25px;
}

.p-t-26 {
  padding-top: 26px;
}

.p-t-27 {
  padding-top: 27px;
}

.p-t-28 {
  padding-top: 28px;
}

.p-t-29 {
  padding-top: 29px;
}

.p-t-30 {
  padding-top: 30px;
}

.p-t-31 {
  padding-top: 31px;
}

.p-t-32 {
  padding-top: 32px;
}

.p-t-33 {
  padding-top: 33px;
}

.p-t-34 {
  padding-top: 34px;
}

.p-t-35 {
  padding-top: 35px;
}

.p-t-36 {
  padding-top: 36px;
}

.p-t-37 {
  padding-top: 37px;
}

.p-t-38 {
  padding-top: 38px;
}

.p-t-39 {
  padding-top: 39px;
}

.p-t-40 {
  padding-top: 40px;
}

.p-t-41 {
  padding-top: 41px;
}

.p-t-42 {
  padding-top: 42px;
}

.p-t-43 {
  padding-top: 43px;
}

.p-t-44 {
  padding-top: 44px;
}

.p-t-45 {
  padding-top: 45px;
}

.p-t-46 {
  padding-top: 46px;
}

.p-t-47 {
  padding-top: 47px;
}

.p-t-48 {
  padding-top: 48px;
}

.p-t-49 {
  padding-top: 49px;
}

.p-t-50 {
  padding-top: 50px;
}

.p-t-51 {
  padding-top: 51px;
}

.p-t-52 {
  padding-top: 52px;
}

.p-t-53 {
  padding-top: 53px;
}

.p-t-54 {
  padding-top: 54px;
}

.p-t-55 {
  padding-top: 55px;
}

.p-t-56 {
  padding-top: 56px;
}

.p-t-57 {
  padding-top: 57px;
}

.p-t-58 {
  padding-top: 58px;
}

.p-t-59 {
  padding-top: 59px;
}

.p-t-60 {
  padding-top: 60px;
}

.p-t-61 {
  padding-top: 61px;
}

.p-t-62 {
  padding-top: 62px;
}

.p-t-63 {
  padding-top: 63px;
}

.p-t-64 {
  padding-top: 64px;
}

.p-t-65 {
  padding-top: 65px;
}

.p-t-66 {
  padding-top: 66px;
}

.p-t-67 {
  padding-top: 67px;
}

.p-t-68 {
  padding-top: 68px;
}

.p-t-69 {
  padding-top: 69px;
}

.p-t-70 {
  padding-top: 70px;
}

.p-t-71 {
  padding-top: 71px;
}

.p-t-72 {
  padding-top: 72px;
}

.p-t-73 {
  padding-top: 73px;
}

.p-t-74 {
  padding-top: 74px;
}

.p-t-75 {
  padding-top: 75px;
}

.p-t-76 {
  padding-top: 76px;
}

.p-t-77 {
  padding-top: 77px;
}

.p-t-78 {
  padding-top: 78px;
}

.p-t-79 {
  padding-top: 79px;
}

.p-t-80 {
  padding-top: 80px;
}

.p-t-81 {
  padding-top: 81px;
}

.p-t-82 {
  padding-top: 82px;
}

.p-t-83 {
  padding-top: 83px;
}

.p-t-84 {
  padding-top: 84px;
}

.p-t-85 {
  padding-top: 85px;
}

.p-t-86 {
  padding-top: 86px;
}

.p-t-87 {
  padding-top: 87px;
}

.p-t-88 {
  padding-top: 88px;
}

.p-t-89 {
  padding-top: 89px;
}

.p-t-90 {
  padding-top: 90px;
}

.p-t-91 {
  padding-top: 91px;
}

.p-t-92 {
  padding-top: 92px;
}

.p-t-93 {
  padding-top: 93px;
}

.p-t-94 {
  padding-top: 94px;
}

.p-t-95 {
  padding-top: 95px;
}

.p-t-96 {
  padding-top: 96px;
}

.p-t-97 {
  padding-top: 97px;
}

.p-t-98 {
  padding-top: 98px;
}

.p-t-99 {
  padding-top: 99px;
}

.p-t-100 {
  padding-top: 100px;
}

.p-t-101 {
  padding-top: 101px;
}

.p-t-102 {
  padding-top: 102px;
}

.p-t-103 {
  padding-top: 103px;
}

.p-t-104 {
  padding-top: 104px;
}

.p-t-105 {
  padding-top: 105px;
}

.p-t-106 {
  padding-top: 106px;
}

.p-t-107 {
  padding-top: 107px;
}

.p-t-108 {
  padding-top: 108px;
}

.p-t-109 {
  padding-top: 109px;
}

.p-t-110 {
  padding-top: 110px;
}

.p-t-111 {
  padding-top: 111px;
}

.p-t-112 {
  padding-top: 112px;
}

.p-t-113 {
  padding-top: 113px;
}

.p-t-114 {
  padding-top: 114px;
}

.p-t-115 {
  padding-top: 115px;
}

.p-t-116 {
  padding-top: 116px;
}

.p-t-117 {
  padding-top: 117px;
}

.p-t-118 {
  padding-top: 118px;
}

.p-t-119 {
  padding-top: 119px;
}

.p-t-120 {
  padding-top: 120px;
}

.p-t-121 {
  padding-top: 121px;
}

.p-t-122 {
  padding-top: 122px;
}

.p-t-123 {
  padding-top: 123px;
}

.p-t-124 {
  padding-top: 124px;
}

.p-t-125 {
  padding-top: 125px;
}

.p-t-126 {
  padding-top: 126px;
}

.p-t-127 {
  padding-top: 127px;
}

.p-t-128 {
  padding-top: 128px;
}

.p-t-129 {
  padding-top: 129px;
}

.p-t-130 {
  padding-top: 130px;
}

.p-t-131 {
  padding-top: 131px;
}

.p-t-132 {
  padding-top: 132px;
}

.p-t-133 {
  padding-top: 133px;
}

.p-t-134 {
  padding-top: 134px;
}

.p-t-135 {
  padding-top: 135px;
}

.p-t-136 {
  padding-top: 136px;
}

.p-t-137 {
  padding-top: 137px;
}

.p-t-138 {
  padding-top: 138px;
}

.p-t-139 {
  padding-top: 139px;
}

.p-t-140 {
  padding-top: 140px;
}

.p-t-141 {
  padding-top: 141px;
}

.p-t-142 {
  padding-top: 142px;
}

.p-t-143 {
  padding-top: 143px;
}

.p-t-144 {
  padding-top: 144px;
}

.p-t-145 {
  padding-top: 145px;
}

.p-t-146 {
  padding-top: 146px;
}

.p-t-147 {
  padding-top: 147px;
}

.p-t-148 {
  padding-top: 148px;
}

.p-t-149 {
  padding-top: 149px;
}

.p-t-150 {
  padding-top: 150px;
}

.p-t-151 {
  padding-top: 151px;
}

.p-t-152 {
  padding-top: 152px;
}

.p-t-153 {
  padding-top: 153px;
}

.p-t-154 {
  padding-top: 154px;
}

.p-t-155 {
  padding-top: 155px;
}

.p-t-156 {
  padding-top: 156px;
}

.p-t-157 {
  padding-top: 157px;
}

.p-t-158 {
  padding-top: 158px;
}

.p-t-159 {
  padding-top: 159px;
}

.p-t-160 {
  padding-top: 160px;
}

.p-t-161 {
  padding-top: 161px;
}

.p-t-162 {
  padding-top: 162px;
}

.p-t-163 {
  padding-top: 163px;
}

.p-t-164 {
  padding-top: 164px;
}

.p-t-165 {
  padding-top: 165px;
}

.p-t-166 {
  padding-top: 166px;
}

.p-t-167 {
  padding-top: 167px;
}

.p-t-168 {
  padding-top: 168px;
}

.p-t-169 {
  padding-top: 169px;
}

.p-t-170 {
  padding-top: 170px;
}

.p-t-171 {
  padding-top: 171px;
}

.p-t-172 {
  padding-top: 172px;
}

.p-t-173 {
  padding-top: 173px;
}

.p-t-174 {
  padding-top: 174px;
}

.p-t-175 {
  padding-top: 175px;
}

.p-t-176 {
  padding-top: 176px;
}

.p-t-177 {
  padding-top: 177px;
}

.p-t-178 {
  padding-top: 178px;
}

.p-t-179 {
  padding-top: 179px;
}

.p-t-180 {
  padding-top: 180px;
}

.p-t-181 {
  padding-top: 181px;
}

.p-t-182 {
  padding-top: 182px;
}

.p-t-183 {
  padding-top: 183px;
}

.p-t-184 {
  padding-top: 184px;
}

.p-t-185 {
  padding-top: 185px;
}

.p-t-186 {
  padding-top: 186px;
}

.p-t-187 {
  padding-top: 187px;
}

.p-t-188 {
  padding-top: 188px;
}

.p-t-189 {
  padding-top: 189px;
}

.p-t-190 {
  padding-top: 190px;
}

.p-t-191 {
  padding-top: 191px;
}

.p-t-192 {
  padding-top: 192px;
}

.p-t-193 {
  padding-top: 193px;
}

.p-t-194 {
  padding-top: 194px;
}

.p-t-195 {
  padding-top: 195px;
}

.p-t-196 {
  padding-top: 196px;
}

.p-t-197 {
  padding-top: 197px;
}

.p-t-198 {
  padding-top: 198px;
}

.p-t-199 {
  padding-top: 199px;
}

.p-t-200 {
  padding-top: 200px;
}

.p-t-201 {
  padding-top: 201px;
}

.p-t-202 {
  padding-top: 202px;
}

.p-t-203 {
  padding-top: 203px;
}

.p-t-204 {
  padding-top: 204px;
}

.p-t-205 {
  padding-top: 205px;
}

.p-t-206 {
  padding-top: 206px;
}

.p-t-207 {
  padding-top: 207px;
}

.p-t-208 {
  padding-top: 208px;
}

.p-t-209 {
  padding-top: 209px;
}

.p-t-210 {
  padding-top: 210px;
}

.p-t-211 {
  padding-top: 211px;
}

.p-t-212 {
  padding-top: 212px;
}

.p-t-213 {
  padding-top: 213px;
}

.p-t-214 {
  padding-top: 214px;
}

.p-t-215 {
  padding-top: 215px;
}

.p-t-216 {
  padding-top: 216px;
}

.p-t-217 {
  padding-top: 217px;
}

.p-t-218 {
  padding-top: 218px;
}

.p-t-219 {
  padding-top: 219px;
}

.p-t-220 {
  padding-top: 220px;
}

.p-t-221 {
  padding-top: 221px;
}

.p-t-222 {
  padding-top: 222px;
}

.p-t-223 {
  padding-top: 223px;
}

.p-t-224 {
  padding-top: 224px;
}

.p-t-225 {
  padding-top: 225px;
}

.p-t-226 {
  padding-top: 226px;
}

.p-t-227 {
  padding-top: 227px;
}

.p-t-228 {
  padding-top: 228px;
}

.p-t-229 {
  padding-top: 229px;
}

.p-t-230 {
  padding-top: 230px;
}

.p-t-231 {
  padding-top: 231px;
}

.p-t-232 {
  padding-top: 232px;
}

.p-t-233 {
  padding-top: 233px;
}

.p-t-234 {
  padding-top: 234px;
}

.p-t-235 {
  padding-top: 235px;
}

.p-t-236 {
  padding-top: 236px;
}

.p-t-237 {
  padding-top: 237px;
}

.p-t-238 {
  padding-top: 238px;
}

.p-t-239 {
  padding-top: 239px;
}

.p-t-240 {
  padding-top: 240px;
}

.p-t-241 {
  padding-top: 241px;
}

.p-t-242 {
  padding-top: 242px;
}

.p-t-243 {
  padding-top: 243px;
}

.p-t-244 {
  padding-top: 244px;
}

.p-t-245 {
  padding-top: 245px;
}

.p-t-246 {
  padding-top: 246px;
}

.p-t-247 {
  padding-top: 247px;
}

.p-t-248 {
  padding-top: 248px;
}

.p-t-249 {
  padding-top: 249px;
}

.p-t-250 {
  padding-top: 250px;
}

.p-b-0 {
  padding-bottom: 0;
}

.p-b-1 {
  padding-bottom: 1px;
}

.p-b-2 {
  padding-bottom: 2px;
}

.p-b-3 {
  padding-bottom: 3px;
}

.p-b-4 {
  padding-bottom: 4px;
}

.p-b-5 {
  padding-bottom: 5px;
}

.p-b-6 {
  padding-bottom: 6px;
}

.p-b-7 {
  padding-bottom: 7px;
}

.p-b-8 {
  padding-bottom: 8px;
}

.p-b-9 {
  padding-bottom: 9px;
}

.p-b-10 {
  padding-bottom: 10px;
}

.p-b-11 {
  padding-bottom: 11px;
}

.p-b-12 {
  padding-bottom: 12px;
}

.p-b-13 {
  padding-bottom: 13px;
}

.p-b-14 {
  padding-bottom: 14px;
}

.p-b-15 {
  padding-bottom: 15px;
}

.p-b-16 {
  padding-bottom: 16px;
}

.p-b-17 {
  padding-bottom: 17px;
}

.p-b-18 {
  padding-bottom: 18px;
}

.p-b-19 {
  padding-bottom: 19px;
}

.p-b-20 {
  padding-bottom: 20px;
}

.p-b-21 {
  padding-bottom: 21px;
}

.p-b-22 {
  padding-bottom: 22px;
}

.p-b-23 {
  padding-bottom: 23px;
}

.p-b-24 {
  padding-bottom: 24px;
}

.p-b-25 {
  padding-bottom: 25px;
}

.p-b-26 {
  padding-bottom: 26px;
}

.p-b-27 {
  padding-bottom: 27px;
}

.p-b-28 {
  padding-bottom: 28px;
}

.p-b-29 {
  padding-bottom: 29px;
}

.p-b-30 {
  padding-bottom: 30px;
}

.p-b-31 {
  padding-bottom: 31px;
}

.p-b-32 {
  padding-bottom: 32px;
}

.p-b-33 {
  padding-bottom: 33px;
}

.p-b-34 {
  padding-bottom: 34px;
}

.p-b-35 {
  padding-bottom: 35px;
}

.p-b-36 {
  padding-bottom: 36px;
}

.p-b-37 {
  padding-bottom: 37px;
}

.p-b-38 {
  padding-bottom: 38px;
}

.p-b-39 {
  padding-bottom: 39px;
}

.p-b-40 {
  padding-bottom: 40px;
}

.p-b-41 {
  padding-bottom: 41px;
}

.p-b-42 {
  padding-bottom: 42px;
}

.p-b-43 {
  padding-bottom: 43px;
}

.p-b-44 {
  padding-bottom: 44px;
}

.p-b-45 {
  padding-bottom: 45px;
}

.p-b-46 {
  padding-bottom: 46px;
}

.p-b-47 {
  padding-bottom: 47px;
}

.p-b-48 {
  padding-bottom: 48px;
}

.p-b-49 {
  padding-bottom: 49px;
}

.p-b-50 {
  padding-bottom: 50px;
}

.p-b-51 {
  padding-bottom: 51px;
}

.p-b-52 {
  padding-bottom: 52px;
}

.p-b-53 {
  padding-bottom: 53px;
}

.p-b-54 {
  padding-bottom: 54px;
}

.p-b-55 {
  padding-bottom: 55px;
}

.p-b-56 {
  padding-bottom: 56px;
}

.p-b-57 {
  padding-bottom: 57px;
}

.p-b-58 {
  padding-bottom: 58px;
}

.p-b-59 {
  padding-bottom: 59px;
}

.p-b-60 {
  padding-bottom: 60px;
}

.p-b-61 {
  padding-bottom: 61px;
}

.p-b-62 {
  padding-bottom: 62px;
}

.p-b-63 {
  padding-bottom: 63px;
}

.p-b-64 {
  padding-bottom: 64px;
}

.p-b-65 {
  padding-bottom: 65px;
}

.p-b-66 {
  padding-bottom: 66px;
}

.p-b-67 {
  padding-bottom: 67px;
}

.p-b-68 {
  padding-bottom: 68px;
}

.p-b-69 {
  padding-bottom: 69px;
}

.p-b-70 {
  padding-bottom: 70px;
}

.p-b-71 {
  padding-bottom: 71px;
}

.p-b-72 {
  padding-bottom: 72px;
}

.p-b-73 {
  padding-bottom: 73px;
}

.p-b-74 {
  padding-bottom: 74px;
}

.p-b-75 {
  padding-bottom: 75px;
}

.p-b-76 {
  padding-bottom: 76px;
}

.p-b-77 {
  padding-bottom: 77px;
}

.p-b-78 {
  padding-bottom: 78px;
}

.p-b-79 {
  padding-bottom: 79px;
}

.p-b-80 {
  padding-bottom: 80px;
}

.p-b-81 {
  padding-bottom: 81px;
}

.p-b-82 {
  padding-bottom: 82px;
}

.p-b-83 {
  padding-bottom: 83px;
}

.p-b-84 {
  padding-bottom: 84px;
}

.p-b-85 {
  padding-bottom: 85px;
}

.p-b-86 {
  padding-bottom: 86px;
}

.p-b-87 {
  padding-bottom: 87px;
}

.p-b-88 {
  padding-bottom: 88px;
}

.p-b-89 {
  padding-bottom: 89px;
}

.p-b-90 {
  padding-bottom: 90px;
}

.p-b-91 {
  padding-bottom: 91px;
}

.p-b-92 {
  padding-bottom: 92px;
}

.p-b-93 {
  padding-bottom: 93px;
}

.p-b-94 {
  padding-bottom: 94px;
}

.p-b-95 {
  padding-bottom: 95px;
}

.p-b-96 {
  padding-bottom: 96px;
}

.p-b-97 {
  padding-bottom: 97px;
}

.p-b-98 {
  padding-bottom: 98px;
}

.p-b-99 {
  padding-bottom: 99px;
}

.p-b-100 {
  padding-bottom: 100px;
}

.p-b-101 {
  padding-bottom: 101px;
}

.p-b-102 {
  padding-bottom: 102px;
}

.p-b-103 {
  padding-bottom: 103px;
}

.p-b-104 {
  padding-bottom: 104px;
}

.p-b-105 {
  padding-bottom: 105px;
}

.p-b-106 {
  padding-bottom: 106px;
}

.p-b-107 {
  padding-bottom: 107px;
}

.p-b-108 {
  padding-bottom: 108px;
}

.p-b-109 {
  padding-bottom: 109px;
}

.p-b-110 {
  padding-bottom: 110px;
}

.p-b-111 {
  padding-bottom: 111px;
}

.p-b-112 {
  padding-bottom: 112px;
}

.p-b-113 {
  padding-bottom: 113px;
}

.p-b-114 {
  padding-bottom: 114px;
}

.p-b-115 {
  padding-bottom: 115px;
}

.p-b-116 {
  padding-bottom: 116px;
}

.p-b-117 {
  padding-bottom: 117px;
}

.p-b-118 {
  padding-bottom: 118px;
}

.p-b-119 {
  padding-bottom: 119px;
}

.p-b-120 {
  padding-bottom: 120px;
}

.p-b-121 {
  padding-bottom: 121px;
}

.p-b-122 {
  padding-bottom: 122px;
}

.p-b-123 {
  padding-bottom: 123px;
}

.p-b-124 {
  padding-bottom: 124px;
}

.p-b-125 {
  padding-bottom: 125px;
}

.p-b-126 {
  padding-bottom: 126px;
}

.p-b-127 {
  padding-bottom: 127px;
}

.p-b-128 {
  padding-bottom: 128px;
}

.p-b-129 {
  padding-bottom: 129px;
}

.p-b-130 {
  padding-bottom: 130px;
}

.p-b-131 {
  padding-bottom: 131px;
}

.p-b-132 {
  padding-bottom: 132px;
}

.p-b-133 {
  padding-bottom: 133px;
}

.p-b-134 {
  padding-bottom: 134px;
}

.p-b-135 {
  padding-bottom: 135px;
}

.p-b-136 {
  padding-bottom: 136px;
}

.p-b-137 {
  padding-bottom: 137px;
}

.p-b-138 {
  padding-bottom: 138px;
}

.p-b-139 {
  padding-bottom: 139px;
}

.p-b-140 {
  padding-bottom: 140px;
}

.p-b-141 {
  padding-bottom: 141px;
}

.p-b-142 {
  padding-bottom: 142px;
}

.p-b-143 {
  padding-bottom: 143px;
}

.p-b-144 {
  padding-bottom: 144px;
}

.p-b-145 {
  padding-bottom: 145px;
}

.p-b-146 {
  padding-bottom: 146px;
}

.p-b-147 {
  padding-bottom: 147px;
}

.p-b-148 {
  padding-bottom: 148px;
}

.p-b-149 {
  padding-bottom: 149px;
}

.p-b-150 {
  padding-bottom: 150px;
}

.p-b-151 {
  padding-bottom: 151px;
}

.p-b-152 {
  padding-bottom: 152px;
}

.p-b-153 {
  padding-bottom: 153px;
}

.p-b-154 {
  padding-bottom: 154px;
}

.p-b-155 {
  padding-bottom: 155px;
}

.p-b-156 {
  padding-bottom: 156px;
}

.p-b-157 {
  padding-bottom: 157px;
}

.p-b-158 {
  padding-bottom: 158px;
}

.p-b-159 {
  padding-bottom: 159px;
}

.p-b-160 {
  padding-bottom: 160px;
}

.p-b-161 {
  padding-bottom: 161px;
}

.p-b-162 {
  padding-bottom: 162px;
}

.p-b-163 {
  padding-bottom: 163px;
}

.p-b-164 {
  padding-bottom: 164px;
}

.p-b-165 {
  padding-bottom: 165px;
}

.p-b-166 {
  padding-bottom: 166px;
}

.p-b-167 {
  padding-bottom: 167px;
}

.p-b-168 {
  padding-bottom: 168px;
}

.p-b-169 {
  padding-bottom: 169px;
}

.p-b-170 {
  padding-bottom: 170px;
}

.p-b-171 {
  padding-bottom: 171px;
}

.p-b-172 {
  padding-bottom: 172px;
}

.p-b-173 {
  padding-bottom: 173px;
}

.p-b-174 {
  padding-bottom: 174px;
}

.p-b-175 {
  padding-bottom: 175px;
}

.p-b-176 {
  padding-bottom: 176px;
}

.p-b-177 {
  padding-bottom: 177px;
}

.p-b-178 {
  padding-bottom: 178px;
}

.p-b-179 {
  padding-bottom: 179px;
}

.p-b-180 {
  padding-bottom: 180px;
}

.p-b-181 {
  padding-bottom: 181px;
}

.p-b-182 {
  padding-bottom: 182px;
}

.p-b-183 {
  padding-bottom: 183px;
}

.p-b-184 {
  padding-bottom: 184px;
}

.p-b-185 {
  padding-bottom: 185px;
}

.p-b-186 {
  padding-bottom: 186px;
}

.p-b-187 {
  padding-bottom: 187px;
}

.p-b-188 {
  padding-bottom: 188px;
}

.p-b-189 {
  padding-bottom: 189px;
}

.p-b-190 {
  padding-bottom: 190px;
}

.p-b-191 {
  padding-bottom: 191px;
}

.p-b-192 {
  padding-bottom: 192px;
}

.p-b-193 {
  padding-bottom: 193px;
}

.p-b-194 {
  padding-bottom: 194px;
}

.p-b-195 {
  padding-bottom: 195px;
}

.p-b-196 {
  padding-bottom: 196px;
}

.p-b-197 {
  padding-bottom: 197px;
}

.p-b-198 {
  padding-bottom: 198px;
}

.p-b-199 {
  padding-bottom: 199px;
}

.p-b-200 {
  padding-bottom: 200px;
}

.p-b-201 {
  padding-bottom: 201px;
}

.p-b-202 {
  padding-bottom: 202px;
}

.p-b-203 {
  padding-bottom: 203px;
}

.p-b-204 {
  padding-bottom: 204px;
}

.p-b-205 {
  padding-bottom: 205px;
}

.p-b-206 {
  padding-bottom: 206px;
}

.p-b-207 {
  padding-bottom: 207px;
}

.p-b-208 {
  padding-bottom: 208px;
}

.p-b-209 {
  padding-bottom: 209px;
}

.p-b-210 {
  padding-bottom: 210px;
}

.p-b-211 {
  padding-bottom: 211px;
}

.p-b-212 {
  padding-bottom: 212px;
}

.p-b-213 {
  padding-bottom: 213px;
}

.p-b-214 {
  padding-bottom: 214px;
}

.p-b-215 {
  padding-bottom: 215px;
}

.p-b-216 {
  padding-bottom: 216px;
}

.p-b-217 {
  padding-bottom: 217px;
}

.p-b-218 {
  padding-bottom: 218px;
}

.p-b-219 {
  padding-bottom: 219px;
}

.p-b-220 {
  padding-bottom: 220px;
}

.p-b-221 {
  padding-bottom: 221px;
}

.p-b-222 {
  padding-bottom: 222px;
}

.p-b-223 {
  padding-bottom: 223px;
}

.p-b-224 {
  padding-bottom: 224px;
}

.p-b-225 {
  padding-bottom: 225px;
}

.p-b-226 {
  padding-bottom: 226px;
}

.p-b-227 {
  padding-bottom: 227px;
}

.p-b-228 {
  padding-bottom: 228px;
}

.p-b-229 {
  padding-bottom: 229px;
}

.p-b-230 {
  padding-bottom: 230px;
}

.p-b-231 {
  padding-bottom: 231px;
}

.p-b-232 {
  padding-bottom: 232px;
}

.p-b-233 {
  padding-bottom: 233px;
}

.p-b-234 {
  padding-bottom: 234px;
}

.p-b-235 {
  padding-bottom: 235px;
}

.p-b-236 {
  padding-bottom: 236px;
}

.p-b-237 {
  padding-bottom: 237px;
}

.p-b-238 {
  padding-bottom: 238px;
}

.p-b-239 {
  padding-bottom: 239px;
}

.p-b-240 {
  padding-bottom: 240px;
}

.p-b-241 {
  padding-bottom: 241px;
}

.p-b-242 {
  padding-bottom: 242px;
}

.p-b-243 {
  padding-bottom: 243px;
}

.p-b-244 {
  padding-bottom: 244px;
}

.p-b-245 {
  padding-bottom: 245px;
}

.p-b-246 {
  padding-bottom: 246px;
}

.p-b-247 {
  padding-bottom: 247px;
}

.p-b-248 {
  padding-bottom: 248px;
}

.p-b-249 {
  padding-bottom: 249px;
}

.p-b-250 {
  padding-bottom: 250px;
}

.p-l-0 {
  padding-left: 0;
}

.p-l-1 {
  padding-left: 1px;
}

.p-l-2 {
  padding-left: 2px;
}

.p-l-3 {
  padding-left: 3px;
}

.p-l-4 {
  padding-left: 4px;
}

.p-l-5 {
  padding-left: 5px;
}

.p-l-6 {
  padding-left: 6px;
}

.p-l-7 {
  padding-left: 7px;
}

.p-l-8 {
  padding-left: 8px;
}

.p-l-9 {
  padding-left: 9px;
}

.p-l-10 {
  padding-left: 10px;
}

.p-l-11 {
  padding-left: 11px;
}

.p-l-12 {
  padding-left: 12px;
}

.p-l-13 {
  padding-left: 13px;
}

.p-l-14 {
  padding-left: 14px;
}

.p-l-15 {
  padding-left: 15px;
}

.p-l-16 {
  padding-left: 16px;
}

.p-l-17 {
  padding-left: 17px;
}

.p-l-18 {
  padding-left: 18px;
}

.p-l-19 {
  padding-left: 19px;
}

.p-l-20 {
  padding-left: 20px;
}

.p-l-21 {
  padding-left: 21px;
}

.p-l-22 {
  padding-left: 22px;
}

.p-l-23 {
  padding-left: 23px;
}

.p-l-24 {
  padding-left: 24px;
}

.p-l-25 {
  padding-left: 25px;
}

.p-l-26 {
  padding-left: 26px;
}

.p-l-27 {
  padding-left: 27px;
}

.p-l-28 {
  padding-left: 28px;
}

.p-l-29 {
  padding-left: 29px;
}

.p-l-30 {
  padding-left: 30px;
}

.p-l-31 {
  padding-left: 31px;
}

.p-l-32 {
  padding-left: 32px;
}

.p-l-33 {
  padding-left: 33px;
}

.p-l-34 {
  padding-left: 34px;
}

.p-l-35 {
  padding-left: 35px;
}

.p-l-36 {
  padding-left: 36px;
}

.p-l-37 {
  padding-left: 37px;
}

.p-l-38 {
  padding-left: 38px;
}

.p-l-39 {
  padding-left: 39px;
}

.p-l-40 {
  padding-left: 40px;
}

.p-l-41 {
  padding-left: 41px;
}

.p-l-42 {
  padding-left: 42px;
}

.p-l-43 {
  padding-left: 43px;
}

.p-l-44 {
  padding-left: 44px;
}

.p-l-45 {
  padding-left: 45px;
}

.p-l-46 {
  padding-left: 46px;
}

.p-l-47 {
  padding-left: 47px;
}

.p-l-48 {
  padding-left: 48px;
}

.p-l-49 {
  padding-left: 49px;
}

.p-l-50 {
  padding-left: 50px;
}

.p-l-51 {
  padding-left: 51px;
}

.p-l-52 {
  padding-left: 52px;
}

.p-l-53 {
  padding-left: 53px;
}

.p-l-54 {
  padding-left: 54px;
}

.p-l-55 {
  padding-left: 55px;
}

.p-l-56 {
  padding-left: 56px;
}

.p-l-57 {
  padding-left: 57px;
}

.p-l-58 {
  padding-left: 58px;
}

.p-l-59 {
  padding-left: 59px;
}

.p-l-60 {
  padding-left: 60px;
}

.p-l-61 {
  padding-left: 61px;
}

.p-l-62 {
  padding-left: 62px;
}

.p-l-63 {
  padding-left: 63px;
}

.p-l-64 {
  padding-left: 64px;
}

.p-l-65 {
  padding-left: 65px;
}

.p-l-66 {
  padding-left: 66px;
}

.p-l-67 {
  padding-left: 67px;
}

.p-l-68 {
  padding-left: 68px;
}

.p-l-69 {
  padding-left: 69px;
}

.p-l-70 {
  padding-left: 70px;
}

.p-l-71 {
  padding-left: 71px;
}

.p-l-72 {
  padding-left: 72px;
}

.p-l-73 {
  padding-left: 73px;
}

.p-l-74 {
  padding-left: 74px;
}

.p-l-75 {
  padding-left: 75px;
}

.p-l-76 {
  padding-left: 76px;
}

.p-l-77 {
  padding-left: 77px;
}

.p-l-78 {
  padding-left: 78px;
}

.p-l-79 {
  padding-left: 79px;
}

.p-l-80 {
  padding-left: 80px;
}

.p-l-81 {
  padding-left: 81px;
}

.p-l-82 {
  padding-left: 82px;
}

.p-l-83 {
  padding-left: 83px;
}

.p-l-84 {
  padding-left: 84px;
}

.p-l-85 {
  padding-left: 85px;
}

.p-l-86 {
  padding-left: 86px;
}

.p-l-87 {
  padding-left: 87px;
}

.p-l-88 {
  padding-left: 88px;
}

.p-l-89 {
  padding-left: 89px;
}

.p-l-90 {
  padding-left: 90px;
}

.p-l-91 {
  padding-left: 91px;
}

.p-l-92 {
  padding-left: 92px;
}

.p-l-93 {
  padding-left: 93px;
}

.p-l-94 {
  padding-left: 94px;
}

.p-l-95 {
  padding-left: 95px;
}

.p-l-96 {
  padding-left: 96px;
}

.p-l-97 {
  padding-left: 97px;
}

.p-l-98 {
  padding-left: 98px;
}

.p-l-99 {
  padding-left: 99px;
}

.p-l-100 {
  padding-left: 100px;
}

.p-l-101 {
  padding-left: 101px;
}

.p-l-102 {
  padding-left: 102px;
}

.p-l-103 {
  padding-left: 103px;
}

.p-l-104 {
  padding-left: 104px;
}

.p-l-105 {
  padding-left: 105px;
}

.p-l-106 {
  padding-left: 106px;
}

.p-l-107 {
  padding-left: 107px;
}

.p-l-108 {
  padding-left: 108px;
}

.p-l-109 {
  padding-left: 109px;
}

.p-l-110 {
  padding-left: 110px;
}

.p-l-111 {
  padding-left: 111px;
}

.p-l-112 {
  padding-left: 112px;
}

.p-l-113 {
  padding-left: 113px;
}

.p-l-114 {
  padding-left: 114px;
}

.p-l-115 {
  padding-left: 115px;
}

.p-l-116 {
  padding-left: 116px;
}

.p-l-117 {
  padding-left: 117px;
}

.p-l-118 {
  padding-left: 118px;
}

.p-l-119 {
  padding-left: 119px;
}

.p-l-120 {
  padding-left: 120px;
}

.p-l-121 {
  padding-left: 121px;
}

.p-l-122 {
  padding-left: 122px;
}

.p-l-123 {
  padding-left: 123px;
}

.p-l-124 {
  padding-left: 124px;
}

.p-l-125 {
  padding-left: 125px;
}

.p-l-126 {
  padding-left: 126px;
}

.p-l-127 {
  padding-left: 127px;
}

.p-l-128 {
  padding-left: 128px;
}

.p-l-129 {
  padding-left: 129px;
}

.p-l-130 {
  padding-left: 130px;
}

.p-l-131 {
  padding-left: 131px;
}

.p-l-132 {
  padding-left: 132px;
}

.p-l-133 {
  padding-left: 133px;
}

.p-l-134 {
  padding-left: 134px;
}

.p-l-135 {
  padding-left: 135px;
}

.p-l-136 {
  padding-left: 136px;
}

.p-l-137 {
  padding-left: 137px;
}

.p-l-138 {
  padding-left: 138px;
}

.p-l-139 {
  padding-left: 139px;
}

.p-l-140 {
  padding-left: 140px;
}

.p-l-141 {
  padding-left: 141px;
}

.p-l-142 {
  padding-left: 142px;
}

.p-l-143 {
  padding-left: 143px;
}

.p-l-144 {
  padding-left: 144px;
}

.p-l-145 {
  padding-left: 145px;
}

.p-l-146 {
  padding-left: 146px;
}

.p-l-147 {
  padding-left: 147px;
}

.p-l-148 {
  padding-left: 148px;
}

.p-l-149 {
  padding-left: 149px;
}

.p-l-150 {
  padding-left: 150px;
}

.p-l-151 {
  padding-left: 151px;
}

.p-l-152 {
  padding-left: 152px;
}

.p-l-153 {
  padding-left: 153px;
}

.p-l-154 {
  padding-left: 154px;
}

.p-l-155 {
  padding-left: 155px;
}

.p-l-156 {
  padding-left: 156px;
}

.p-l-157 {
  padding-left: 157px;
}

.p-l-158 {
  padding-left: 158px;
}

.p-l-159 {
  padding-left: 159px;
}

.p-l-160 {
  padding-left: 160px;
}

.p-l-161 {
  padding-left: 161px;
}

.p-l-162 {
  padding-left: 162px;
}

.p-l-163 {
  padding-left: 163px;
}

.p-l-164 {
  padding-left: 164px;
}

.p-l-165 {
  padding-left: 165px;
}

.p-l-166 {
  padding-left: 166px;
}

.p-l-167 {
  padding-left: 167px;
}

.p-l-168 {
  padding-left: 168px;
}

.p-l-169 {
  padding-left: 169px;
}

.p-l-170 {
  padding-left: 170px;
}

.p-l-171 {
  padding-left: 171px;
}

.p-l-172 {
  padding-left: 172px;
}

.p-l-173 {
  padding-left: 173px;
}

.p-l-174 {
  padding-left: 174px;
}

.p-l-175 {
  padding-left: 175px;
}

.p-l-176 {
  padding-left: 176px;
}

.p-l-177 {
  padding-left: 177px;
}

.p-l-178 {
  padding-left: 178px;
}

.p-l-179 {
  padding-left: 179px;
}

.p-l-180 {
  padding-left: 180px;
}

.p-l-181 {
  padding-left: 181px;
}

.p-l-182 {
  padding-left: 182px;
}

.p-l-183 {
  padding-left: 183px;
}

.p-l-184 {
  padding-left: 184px;
}

.p-l-185 {
  padding-left: 185px;
}

.p-l-186 {
  padding-left: 186px;
}

.p-l-187 {
  padding-left: 187px;
}

.p-l-188 {
  padding-left: 188px;
}

.p-l-189 {
  padding-left: 189px;
}

.p-l-190 {
  padding-left: 190px;
}

.p-l-191 {
  padding-left: 191px;
}

.p-l-192 {
  padding-left: 192px;
}

.p-l-193 {
  padding-left: 193px;
}

.p-l-194 {
  padding-left: 194px;
}

.p-l-195 {
  padding-left: 195px;
}

.p-l-196 {
  padding-left: 196px;
}

.p-l-197 {
  padding-left: 197px;
}

.p-l-198 {
  padding-left: 198px;
}

.p-l-199 {
  padding-left: 199px;
}

.p-l-200 {
  padding-left: 200px;
}

.p-l-201 {
  padding-left: 201px;
}

.p-l-202 {
  padding-left: 202px;
}

.p-l-203 {
  padding-left: 203px;
}

.p-l-204 {
  padding-left: 204px;
}

.p-l-205 {
  padding-left: 205px;
}

.p-l-206 {
  padding-left: 206px;
}

.p-l-207 {
  padding-left: 207px;
}

.p-l-208 {
  padding-left: 208px;
}

.p-l-209 {
  padding-left: 209px;
}

.p-l-210 {
  padding-left: 210px;
}

.p-l-211 {
  padding-left: 211px;
}

.p-l-212 {
  padding-left: 212px;
}

.p-l-213 {
  padding-left: 213px;
}

.p-l-214 {
  padding-left: 214px;
}

.p-l-215 {
  padding-left: 215px;
}

.p-l-216 {
  padding-left: 216px;
}

.p-l-217 {
  padding-left: 217px;
}

.p-l-218 {
  padding-left: 218px;
}

.p-l-219 {
  padding-left: 219px;
}

.p-l-220 {
  padding-left: 220px;
}

.p-l-221 {
  padding-left: 221px;
}

.p-l-222 {
  padding-left: 222px;
}

.p-l-223 {
  padding-left: 223px;
}

.p-l-224 {
  padding-left: 224px;
}

.p-l-225 {
  padding-left: 225px;
}

.p-l-226 {
  padding-left: 226px;
}

.p-l-227 {
  padding-left: 227px;
}

.p-l-228 {
  padding-left: 228px;
}

.p-l-229 {
  padding-left: 229px;
}

.p-l-230 {
  padding-left: 230px;
}

.p-l-231 {
  padding-left: 231px;
}

.p-l-232 {
  padding-left: 232px;
}

.p-l-233 {
  padding-left: 233px;
}

.p-l-234 {
  padding-left: 234px;
}

.p-l-235 {
  padding-left: 235px;
}

.p-l-236 {
  padding-left: 236px;
}

.p-l-237 {
  padding-left: 237px;
}

.p-l-238 {
  padding-left: 238px;
}

.p-l-239 {
  padding-left: 239px;
}

.p-l-240 {
  padding-left: 240px;
}

.p-l-241 {
  padding-left: 241px;
}

.p-l-242 {
  padding-left: 242px;
}

.p-l-243 {
  padding-left: 243px;
}

.p-l-244 {
  padding-left: 244px;
}

.p-l-245 {
  padding-left: 245px;
}

.p-l-246 {
  padding-left: 246px;
}

.p-l-247 {
  padding-left: 247px;
}

.p-l-248 {
  padding-left: 248px;
}

.p-l-249 {
  padding-left: 249px;
}

.p-l-250 {
  padding-left: 250px;
}

.p-r-0 {
  padding-right: 0;
}

.p-r-1 {
  padding-right: 1px;
}

.p-r-2 {
  padding-right: 2px;
}

.p-r-3 {
  padding-right: 3px;
}

.p-r-4 {
  padding-right: 4px;
}

.p-r-5 {
  padding-right: 5px;
}

.p-r-6 {
  padding-right: 6px;
}

.p-r-7 {
  padding-right: 7px;
}

.p-r-8 {
  padding-right: 8px;
}

.p-r-9 {
  padding-right: 9px;
}

.p-r-10 {
  padding-right: 10px;
}

.p-r-11 {
  padding-right: 11px;
}

.p-r-12 {
  padding-right: 12px;
}

.p-r-13 {
  padding-right: 13px;
}

.p-r-14 {
  padding-right: 14px;
}

.p-r-15 {
  padding-right: 15px;
}

.p-r-16 {
  padding-right: 16px;
}

.p-r-17 {
  padding-right: 17px;
}

.p-r-18 {
  padding-right: 18px;
}

.p-r-19 {
  padding-right: 19px;
}

.p-r-20 {
  padding-right: 20px;
}

.p-r-21 {
  padding-right: 21px;
}

.p-r-22 {
  padding-right: 22px;
}

.p-r-23 {
  padding-right: 23px;
}

.p-r-24 {
  padding-right: 24px;
}

.p-r-25 {
  padding-right: 25px;
}

.p-r-26 {
  padding-right: 26px;
}

.p-r-27 {
  padding-right: 27px;
}

.p-r-28 {
  padding-right: 28px;
}

.p-r-29 {
  padding-right: 29px;
}

.p-r-30 {
  padding-right: 30px;
}

.p-r-31 {
  padding-right: 31px;
}

.p-r-32 {
  padding-right: 32px;
}

.p-r-33 {
  padding-right: 33px;
}

.p-r-34 {
  padding-right: 34px;
}

.p-r-35 {
  padding-right: 35px;
}

.p-r-36 {
  padding-right: 36px;
}

.p-r-37 {
  padding-right: 37px;
}

.p-r-38 {
  padding-right: 38px;
}

.p-r-39 {
  padding-right: 39px;
}

.p-r-40 {
  padding-right: 40px;
}

.p-r-41 {
  padding-right: 41px;
}

.p-r-42 {
  padding-right: 42px;
}

.p-r-43 {
  padding-right: 43px;
}

.p-r-44 {
  padding-right: 44px;
}

.p-r-45 {
  padding-right: 45px;
}

.p-r-46 {
  padding-right: 46px;
}

.p-r-47 {
  padding-right: 47px;
}

.p-r-48 {
  padding-right: 48px;
}

.p-r-49 {
  padding-right: 49px;
}

.p-r-50 {
  padding-right: 50px;
}

.p-r-51 {
  padding-right: 51px;
}

.p-r-52 {
  padding-right: 52px;
}

.p-r-53 {
  padding-right: 53px;
}

.p-r-54 {
  padding-right: 54px;
}

.p-r-55 {
  padding-right: 55px;
}

.p-r-56 {
  padding-right: 56px;
}

.p-r-57 {
  padding-right: 57px;
}

.p-r-58 {
  padding-right: 58px;
}

.p-r-59 {
  padding-right: 59px;
}

.p-r-60 {
  padding-right: 60px;
}

.p-r-61 {
  padding-right: 61px;
}

.p-r-62 {
  padding-right: 62px;
}

.p-r-63 {
  padding-right: 63px;
}

.p-r-64 {
  padding-right: 64px;
}

.p-r-65 {
  padding-right: 65px;
}

.p-r-66 {
  padding-right: 66px;
}

.p-r-67 {
  padding-right: 67px;
}

.p-r-68 {
  padding-right: 68px;
}

.p-r-69 {
  padding-right: 69px;
}

.p-r-70 {
  padding-right: 70px;
}

.p-r-71 {
  padding-right: 71px;
}

.p-r-72 {
  padding-right: 72px;
}

.p-r-73 {
  padding-right: 73px;
}

.p-r-74 {
  padding-right: 74px;
}

.p-r-75 {
  padding-right: 75px;
}

.p-r-76 {
  padding-right: 76px;
}

.p-r-77 {
  padding-right: 77px;
}

.p-r-78 {
  padding-right: 78px;
}

.p-r-79 {
  padding-right: 79px;
}

.p-r-80 {
  padding-right: 80px;
}

.p-r-81 {
  padding-right: 81px;
}

.p-r-82 {
  padding-right: 82px;
}

.p-r-83 {
  padding-right: 83px;
}

.p-r-84 {
  padding-right: 84px;
}

.p-r-85 {
  padding-right: 85px;
}

.p-r-86 {
  padding-right: 86px;
}

.p-r-87 {
  padding-right: 87px;
}

.p-r-88 {
  padding-right: 88px;
}

.p-r-89 {
  padding-right: 89px;
}

.p-r-90 {
  padding-right: 90px;
}

.p-r-91 {
  padding-right: 91px;
}

.p-r-92 {
  padding-right: 92px;
}

.p-r-93 {
  padding-right: 93px;
}

.p-r-94 {
  padding-right: 94px;
}

.p-r-95 {
  padding-right: 95px;
}

.p-r-96 {
  padding-right: 96px;
}

.p-r-97 {
  padding-right: 97px;
}

.p-r-98 {
  padding-right: 98px;
}

.p-r-99 {
  padding-right: 99px;
}

.p-r-100 {
  padding-right: 100px;
}

.p-r-101 {
  padding-right: 101px;
}

.p-r-102 {
  padding-right: 102px;
}

.p-r-103 {
  padding-right: 103px;
}

.p-r-104 {
  padding-right: 104px;
}

.p-r-105 {
  padding-right: 105px;
}

.p-r-106 {
  padding-right: 106px;
}

.p-r-107 {
  padding-right: 107px;
}

.p-r-108 {
  padding-right: 108px;
}

.p-r-109 {
  padding-right: 109px;
}

.p-r-110 {
  padding-right: 110px;
}

.p-r-111 {
  padding-right: 111px;
}

.p-r-112 {
  padding-right: 112px;
}

.p-r-113 {
  padding-right: 113px;
}

.p-r-114 {
  padding-right: 114px;
}

.p-r-115 {
  padding-right: 115px;
}

.p-r-116 {
  padding-right: 116px;
}

.p-r-117 {
  padding-right: 117px;
}

.p-r-118 {
  padding-right: 118px;
}

.p-r-119 {
  padding-right: 119px;
}

.p-r-120 {
  padding-right: 120px;
}

.p-r-121 {
  padding-right: 121px;
}

.p-r-122 {
  padding-right: 122px;
}

.p-r-123 {
  padding-right: 123px;
}

.p-r-124 {
  padding-right: 124px;
}

.p-r-125 {
  padding-right: 125px;
}

.p-r-126 {
  padding-right: 126px;
}

.p-r-127 {
  padding-right: 127px;
}

.p-r-128 {
  padding-right: 128px;
}

.p-r-129 {
  padding-right: 129px;
}

.p-r-130 {
  padding-right: 130px;
}

.p-r-131 {
  padding-right: 131px;
}

.p-r-132 {
  padding-right: 132px;
}

.p-r-133 {
  padding-right: 133px;
}

.p-r-134 {
  padding-right: 134px;
}

.p-r-135 {
  padding-right: 135px;
}

.p-r-136 {
  padding-right: 136px;
}

.p-r-137 {
  padding-right: 137px;
}

.p-r-138 {
  padding-right: 138px;
}

.p-r-139 {
  padding-right: 139px;
}

.p-r-140 {
  padding-right: 140px;
}

.p-r-141 {
  padding-right: 141px;
}

.p-r-142 {
  padding-right: 142px;
}

.p-r-143 {
  padding-right: 143px;
}

.p-r-144 {
  padding-right: 144px;
}

.p-r-145 {
  padding-right: 145px;
}

.p-r-146 {
  padding-right: 146px;
}

.p-r-147 {
  padding-right: 147px;
}

.p-r-148 {
  padding-right: 148px;
}

.p-r-149 {
  padding-right: 149px;
}

.p-r-150 {
  padding-right: 150px;
}

.p-r-151 {
  padding-right: 151px;
}

.p-r-152 {
  padding-right: 152px;
}

.p-r-153 {
  padding-right: 153px;
}

.p-r-154 {
  padding-right: 154px;
}

.p-r-155 {
  padding-right: 155px;
}

.p-r-156 {
  padding-right: 156px;
}

.p-r-157 {
  padding-right: 157px;
}

.p-r-158 {
  padding-right: 158px;
}

.p-r-159 {
  padding-right: 159px;
}

.p-r-160 {
  padding-right: 160px;
}

.p-r-161 {
  padding-right: 161px;
}

.p-r-162 {
  padding-right: 162px;
}

.p-r-163 {
  padding-right: 163px;
}

.p-r-164 {
  padding-right: 164px;
}

.p-r-165 {
  padding-right: 165px;
}

.p-r-166 {
  padding-right: 166px;
}

.p-r-167 {
  padding-right: 167px;
}

.p-r-168 {
  padding-right: 168px;
}

.p-r-169 {
  padding-right: 169px;
}

.p-r-170 {
  padding-right: 170px;
}

.p-r-171 {
  padding-right: 171px;
}

.p-r-172 {
  padding-right: 172px;
}

.p-r-173 {
  padding-right: 173px;
}

.p-r-174 {
  padding-right: 174px;
}

.p-r-175 {
  padding-right: 175px;
}

.p-r-176 {
  padding-right: 176px;
}

.p-r-177 {
  padding-right: 177px;
}

.p-r-178 {
  padding-right: 178px;
}

.p-r-179 {
  padding-right: 179px;
}

.p-r-180 {
  padding-right: 180px;
}

.p-r-181 {
  padding-right: 181px;
}

.p-r-182 {
  padding-right: 182px;
}

.p-r-183 {
  padding-right: 183px;
}

.p-r-184 {
  padding-right: 184px;
}

.p-r-185 {
  padding-right: 185px;
}

.p-r-186 {
  padding-right: 186px;
}

.p-r-187 {
  padding-right: 187px;
}

.p-r-188 {
  padding-right: 188px;
}

.p-r-189 {
  padding-right: 189px;
}

.p-r-190 {
  padding-right: 190px;
}

.p-r-191 {
  padding-right: 191px;
}

.p-r-192 {
  padding-right: 192px;
}

.p-r-193 {
  padding-right: 193px;
}

.p-r-194 {
  padding-right: 194px;
}

.p-r-195 {
  padding-right: 195px;
}

.p-r-196 {
  padding-right: 196px;
}

.p-r-197 {
  padding-right: 197px;
}

.p-r-198 {
  padding-right: 198px;
}

.p-r-199 {
  padding-right: 199px;
}

.p-r-200 {
  padding-right: 200px;
}

.p-r-201 {
  padding-right: 201px;
}

.p-r-202 {
  padding-right: 202px;
}

.p-r-203 {
  padding-right: 203px;
}

.p-r-204 {
  padding-right: 204px;
}

.p-r-205 {
  padding-right: 205px;
}

.p-r-206 {
  padding-right: 206px;
}

.p-r-207 {
  padding-right: 207px;
}

.p-r-208 {
  padding-right: 208px;
}

.p-r-209 {
  padding-right: 209px;
}

.p-r-210 {
  padding-right: 210px;
}

.p-r-211 {
  padding-right: 211px;
}

.p-r-212 {
  padding-right: 212px;
}

.p-r-213 {
  padding-right: 213px;
}

.p-r-214 {
  padding-right: 214px;
}

.p-r-215 {
  padding-right: 215px;
}

.p-r-216 {
  padding-right: 216px;
}

.p-r-217 {
  padding-right: 217px;
}

.p-r-218 {
  padding-right: 218px;
}

.p-r-219 {
  padding-right: 219px;
}

.p-r-220 {
  padding-right: 220px;
}

.p-r-221 {
  padding-right: 221px;
}

.p-r-222 {
  padding-right: 222px;
}

.p-r-223 {
  padding-right: 223px;
}

.p-r-224 {
  padding-right: 224px;
}

.p-r-225 {
  padding-right: 225px;
}

.p-r-226 {
  padding-right: 226px;
}

.p-r-227 {
  padding-right: 227px;
}

.p-r-228 {
  padding-right: 228px;
}

.p-r-229 {
  padding-right: 229px;
}

.p-r-230 {
  padding-right: 230px;
}

.p-r-231 {
  padding-right: 231px;
}

.p-r-232 {
  padding-right: 232px;
}

.p-r-233 {
  padding-right: 233px;
}

.p-r-234 {
  padding-right: 234px;
}

.p-r-235 {
  padding-right: 235px;
}

.p-r-236 {
  padding-right: 236px;
}

.p-r-237 {
  padding-right: 237px;
}

.p-r-238 {
  padding-right: 238px;
}

.p-r-239 {
  padding-right: 239px;
}

.p-r-240 {
  padding-right: 240px;
}

.p-r-241 {
  padding-right: 241px;
}

.p-r-242 {
  padding-right: 242px;
}

.p-r-243 {
  padding-right: 243px;
}

.p-r-244 {
  padding-right: 244px;
}

.p-r-245 {
  padding-right: 245px;
}

.p-r-246 {
  padding-right: 246px;
}

.p-r-247 {
  padding-right: 247px;
}

.p-r-248 {
  padding-right: 248px;
}

.p-r-249 {
  padding-right: 249px;
}

.p-r-250 {
  padding-right: 250px;
}

.m-t-0 {
  margin-top: 0 !important;
}

.m-t-1 {
  margin-top: 1px;
}

.m-t-2 {
  margin-top: 2px;
}

.m-t-3 {
  margin-top: 3px;
}

.m-t-4 {
  margin-top: 4px;
}

.m-t-5 {
  margin-top: 5px;
}

.m-t-6 {
  margin-top: 6px;
}

.m-t-7 {
  margin-top: 7px;
}

.m-t-8 {
  margin-top: 8px;
}

.m-t-9 {
  margin-top: 9px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-t-11 {
  margin-top: 11px;
}

.m-t-12 {
  margin-top: 12px;
}

.m-t-13 {
  margin-top: 13px;
}

.m-t-14 {
  margin-top: 14px;
}

.m-t-15 {
  margin-top: 15px;
}

.m-t-16 {
  margin-top: 16px;
}

.m-t-17 {
  margin-top: 17px;
}

.m-t-18 {
  margin-top: 18px;
}

.m-t-19 {
  margin-top: 19px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-t-21 {
  margin-top: 21px;
}

.m-t-22 {
  margin-top: 22px;
}

.m-t-23 {
  margin-top: 23px;
}

.m-t-24 {
  margin-top: 24px;
}

.m-t-25 {
  margin-top: 25px;
}

.m-t-26 {
  margin-top: 26px;
}

.m-t-27 {
  margin-top: 27px;
}

.m-t-28 {
  margin-top: 28px;
}

.m-t-29 {
  margin-top: 29px;
}

.m-t-30 {
  margin-top: 30px;
}

.m-t-31 {
  margin-top: 31px;
}

.m-t-32 {
  margin-top: 32px;
}

.m-t-33 {
  margin-top: 33px;
}

.m-t-34 {
  margin-top: 34px;
}

.m-t-35 {
  margin-top: 35px;
}

.m-t-36 {
  margin-top: 36px;
}

.m-t-37 {
  margin-top: 37px;
}

.m-t-38 {
  margin-top: 38px;
}

.m-t-39 {
  margin-top: 39px;
}

.m-t-40 {
  margin-top: 40px;
}

.m-t-41 {
  margin-top: 41px;
}

.m-t-42 {
  margin-top: 42px;
}

.m-t-43 {
  margin-top: 43px;
}

.m-t-44 {
  margin-top: 44px;
}

.m-t-45 {
  margin-top: 45px;
}

.m-t-46 {
  margin-top: 46px;
}

.m-t-47 {
  margin-top: 47px;
}

.m-t-48 {
  margin-top: 48px;
}

.m-t-49 {
  margin-top: 49px;
}

.m-t-50 {
  margin-top: 50px;
}

.m-t-51 {
  margin-top: 51px;
}

.m-t-52 {
  margin-top: 52px;
}

.m-t-53 {
  margin-top: 53px;
}

.m-t-54 {
  margin-top: 54px;
}

.m-t-55 {
  margin-top: 55px;
}

.m-t-56 {
  margin-top: 56px;
}

.m-t-57 {
  margin-top: 57px;
}

.m-t-58 {
  margin-top: 58px;
}

.m-t-59 {
  margin-top: 59px;
}

.m-t-60 {
  margin-top: 60px;
}

.m-t-61 {
  margin-top: 61px;
}

.m-t-62 {
  margin-top: 62px;
}

.m-t-63 {
  margin-top: 63px;
}

.m-t-64 {
  margin-top: 64px;
}

.m-t-65 {
  margin-top: 65px;
}

.m-t-66 {
  margin-top: 66px;
}

.m-t-67 {
  margin-top: 67px;
}

.m-t-68 {
  margin-top: 68px;
}

.m-t-69 {
  margin-top: 69px;
}

.m-t-70 {
  margin-top: 70px;
}

.m-t-71 {
  margin-top: 71px;
}

.m-t-72 {
  margin-top: 72px;
}

.m-t-73 {
  margin-top: 73px;
}

.m-t-74 {
  margin-top: 74px;
}

.m-t-75 {
  margin-top: 75px;
}

.m-t-76 {
  margin-top: 76px;
}

.m-t-77 {
  margin-top: 77px;
}

.m-t-78 {
  margin-top: 78px;
}

.m-t-79 {
  margin-top: 79px;
}

.m-t-80 {
  margin-top: 80px;
}

.m-t-81 {
  margin-top: 81px;
}

.m-t-82 {
  margin-top: 82px;
}

.m-t-83 {
  margin-top: 83px;
}

.m-t-84 {
  margin-top: 84px;
}

.m-t-85 {
  margin-top: 85px;
}

.m-t-86 {
  margin-top: 86px;
}

.m-t-87 {
  margin-top: 87px;
}

.m-t-88 {
  margin-top: 88px;
}

.m-t-89 {
  margin-top: 89px;
}

.m-t-90 {
  margin-top: 90px;
}

.m-t-91 {
  margin-top: 91px;
}

.m-t-92 {
  margin-top: 92px;
}

.m-t-93 {
  margin-top: 93px;
}

.m-t-94 {
  margin-top: 94px;
}

.m-t-95 {
  margin-top: 95px;
}

.m-t-96 {
  margin-top: 96px;
}

.m-t-97 {
  margin-top: 97px;
}

.m-t-98 {
  margin-top: 98px;
}

.m-t-99 {
  margin-top: 99px;
}

.m-t-100 {
  margin-top: 100px;
}

.m-t-101 {
  margin-top: 101px;
}

.m-t-102 {
  margin-top: 102px;
}

.m-t-103 {
  margin-top: 103px;
}

.m-t-104 {
  margin-top: 104px;
}

.m-t-105 {
  margin-top: 105px;
}

.m-t-106 {
  margin-top: 106px;
}

.m-t-107 {
  margin-top: 107px;
}

.m-t-108 {
  margin-top: 108px;
}

.m-t-109 {
  margin-top: 109px;
}

.m-t-110 {
  margin-top: 110px;
}

.m-t-111 {
  margin-top: 111px;
}

.m-t-112 {
  margin-top: 112px;
}

.m-t-113 {
  margin-top: 113px;
}

.m-t-114 {
  margin-top: 114px;
}

.m-t-115 {
  margin-top: 115px;
}

.m-t-116 {
  margin-top: 116px;
}

.m-t-117 {
  margin-top: 117px;
}

.m-t-118 {
  margin-top: 118px;
}

.m-t-119 {
  margin-top: 119px;
}

.m-t-120 {
  margin-top: 120px;
}

.m-t-121 {
  margin-top: 121px;
}

.m-t-122 {
  margin-top: 122px;
}

.m-t-123 {
  margin-top: 123px;
}

.m-t-124 {
  margin-top: 124px;
}

.m-t-125 {
  margin-top: 125px;
}

.m-t-126 {
  margin-top: 126px;
}

.m-t-127 {
  margin-top: 127px;
}

.m-t-128 {
  margin-top: 128px;
}

.m-t-129 {
  margin-top: 129px;
}

.m-t-130 {
  margin-top: 130px;
}

.m-t-131 {
  margin-top: 131px;
}

.m-t-132 {
  margin-top: 132px;
}

.m-t-133 {
  margin-top: 133px;
}

.m-t-134 {
  margin-top: 134px;
}

.m-t-135 {
  margin-top: 135px;
}

.m-t-136 {
  margin-top: 136px;
}

.m-t-137 {
  margin-top: 137px;
}

.m-t-138 {
  margin-top: 138px;
}

.m-t-139 {
  margin-top: 139px;
}

.m-t-140 {
  margin-top: 140px;
}

.m-t-141 {
  margin-top: 141px;
}

.m-t-142 {
  margin-top: 142px;
}

.m-t-143 {
  margin-top: 143px;
}

.m-t-144 {
  margin-top: 144px;
}

.m-t-145 {
  margin-top: 145px;
}

.m-t-146 {
  margin-top: 146px;
}

.m-t-147 {
  margin-top: 147px;
}

.m-t-148 {
  margin-top: 148px;
}

.m-t-149 {
  margin-top: 149px;
}

.m-t-150 {
  margin-top: 150px;
}

.m-t-151 {
  margin-top: 151px;
}

.m-t-152 {
  margin-top: 152px;
}

.m-t-153 {
  margin-top: 153px;
}

.m-t-154 {
  margin-top: 154px;
}

.m-t-155 {
  margin-top: 155px;
}

.m-t-156 {
  margin-top: 156px;
}

.m-t-157 {
  margin-top: 157px;
}

.m-t-158 {
  margin-top: 158px;
}

.m-t-159 {
  margin-top: 159px;
}

.m-t-160 {
  margin-top: 160px;
}

.m-t-161 {
  margin-top: 161px;
}

.m-t-162 {
  margin-top: 162px;
}

.m-t-163 {
  margin-top: 163px;
}

.m-t-164 {
  margin-top: 164px;
}

.m-t-165 {
  margin-top: 165px;
}

.m-t-166 {
  margin-top: 166px;
}

.m-t-167 {
  margin-top: 167px;
}

.m-t-168 {
  margin-top: 168px;
}

.m-t-169 {
  margin-top: 169px;
}

.m-t-170 {
  margin-top: 170px;
}

.m-t-171 {
  margin-top: 171px;
}

.m-t-172 {
  margin-top: 172px;
}

.m-t-173 {
  margin-top: 173px;
}

.m-t-174 {
  margin-top: 174px;
}

.m-t-175 {
  margin-top: 175px;
}

.m-t-176 {
  margin-top: 176px;
}

.m-t-177 {
  margin-top: 177px;
}

.m-t-178 {
  margin-top: 178px;
}

.m-t-179 {
  margin-top: 179px;
}

.m-t-180 {
  margin-top: 180px;
}

.m-t-181 {
  margin-top: 181px;
}

.m-t-182 {
  margin-top: 182px;
}

.m-t-183 {
  margin-top: 183px;
}

.m-t-184 {
  margin-top: 184px;
}

.m-t-185 {
  margin-top: 185px;
}

.m-t-186 {
  margin-top: 186px;
}

.m-t-187 {
  margin-top: 187px;
}

.m-t-188 {
  margin-top: 188px;
}

.m-t-189 {
  margin-top: 189px;
}

.m-t-190 {
  margin-top: 190px;
}

.m-t-191 {
  margin-top: 191px;
}

.m-t-192 {
  margin-top: 192px;
}

.m-t-193 {
  margin-top: 193px;
}

.m-t-194 {
  margin-top: 194px;
}

.m-t-195 {
  margin-top: 195px;
}

.m-t-196 {
  margin-top: 196px;
}

.m-t-197 {
  margin-top: 197px;
}

.m-t-198 {
  margin-top: 198px;
}

.m-t-199 {
  margin-top: 199px;
}

.m-t-200 {
  margin-top: 200px;
}

.m-t-201 {
  margin-top: 201px;
}

.m-t-202 {
  margin-top: 202px;
}

.m-t-203 {
  margin-top: 203px;
}

.m-t-204 {
  margin-top: 204px;
}

.m-t-205 {
  margin-top: 205px;
}

.m-t-206 {
  margin-top: 206px;
}

.m-t-207 {
  margin-top: 207px;
}

.m-t-208 {
  margin-top: 208px;
}

.m-t-209 {
  margin-top: 209px;
}

.m-t-210 {
  margin-top: 210px;
}

.m-t-211 {
  margin-top: 211px;
}

.m-t-212 {
  margin-top: 212px;
}

.m-t-213 {
  margin-top: 213px;
}

.m-t-214 {
  margin-top: 214px;
}

.m-t-215 {
  margin-top: 215px;
}

.m-t-216 {
  margin-top: 216px;
}

.m-t-217 {
  margin-top: 217px;
}

.m-t-218 {
  margin-top: 218px;
}

.m-t-219 {
  margin-top: 219px;
}

.m-t-220 {
  margin-top: 220px;
}

.m-t-221 {
  margin-top: 221px;
}

.m-t-222 {
  margin-top: 222px;
}

.m-t-223 {
  margin-top: 223px;
}

.m-t-224 {
  margin-top: 224px;
}

.m-t-225 {
  margin-top: 225px;
}

.m-t-226 {
  margin-top: 226px;
}

.m-t-227 {
  margin-top: 227px;
}

.m-t-228 {
  margin-top: 228px;
}

.m-t-229 {
  margin-top: 229px;
}

.m-t-230 {
  margin-top: 230px;
}

.m-t-231 {
  margin-top: 231px;
}

.m-t-232 {
  margin-top: 232px;
}

.m-t-233 {
  margin-top: 233px;
}

.m-t-234 {
  margin-top: 234px;
}

.m-t-235 {
  margin-top: 235px;
}

.m-t-236 {
  margin-top: 236px;
}

.m-t-237 {
  margin-top: 237px;
}

.m-t-238 {
  margin-top: 238px;
}

.m-t-239 {
  margin-top: 239px;
}

.m-t-240 {
  margin-top: 240px;
}

.m-t-241 {
  margin-top: 241px;
}

.m-t-242 {
  margin-top: 242px;
}

.m-t-243 {
  margin-top: 243px;
}

.m-t-244 {
  margin-top: 244px;
}

.m-t-245 {
  margin-top: 245px;
}

.m-t-246 {
  margin-top: 246px;
}

.m-t-247 {
  margin-top: 247px;
}

.m-t-248 {
  margin-top: 248px;
}

.m-t-249 {
  margin-top: 249px;
}

.m-t-250 {
  margin-top: 250px;
}

.m-b-0 {
  margin-bottom: 0;
}

.m-b-1 {
  margin-bottom: 1px;
}

.m-b-2 {
  margin-bottom: 2px;
}

.m-b-3 {
  margin-bottom: 3px;
}

.m-b-4 {
  margin-bottom: 4px;
}

.m-b-5 {
  margin-bottom: 5px;
}

.m-b-6 {
  margin-bottom: 6px;
}

.m-b-7 {
  margin-bottom: 7px;
}

.m-b-8 {
  margin-bottom: 8px;
}

.m-b-9 {
  margin-bottom: 9px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-b-11 {
  margin-bottom: 11px;
}

.m-b-12 {
  margin-bottom: 12px;
}

.m-b-13 {
  margin-bottom: 13px;
}

.m-b-14 {
  margin-bottom: 14px;
}

.m-b-15 {
  margin-bottom: 15px;
}

.m-b-16 {
  margin-bottom: 16px;
}

.m-b-17 {
  margin-bottom: 17px;
}

.m-b-18 {
  margin-bottom: 18px;
}

.m-b-19 {
  margin-bottom: 19px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-b-21 {
  margin-bottom: 21px;
}

.m-b-22 {
  margin-bottom: 22px;
}

.m-b-23 {
  margin-bottom: 23px;
}

.m-b-24 {
  margin-bottom: 24px;
}

.m-b-25 {
  margin-bottom: 25px;
}

.m-b-26 {
  margin-bottom: 26px;
}

.m-b-27 {
  margin-bottom: 27px;
}

.m-b-28 {
  margin-bottom: 28px;
}

.m-b-29 {
  margin-bottom: 29px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.m-b-31 {
  margin-bottom: 31px;
}

.m-b-32 {
  margin-bottom: 32px;
}

.m-b-33 {
  margin-bottom: 33px;
}

.m-b-34 {
  margin-bottom: 34px;
}

.m-b-35 {
  margin-bottom: 35px;
}

.m-b-36 {
  margin-bottom: 36px;
}

.m-b-37 {
  margin-bottom: 37px;
}

.m-b-38 {
  margin-bottom: 38px;
}

.m-b-39 {
  margin-bottom: 39px;
}

.m-b-40 {
  margin-bottom: 40px;
}

.m-b-41 {
  margin-bottom: 41px;
}

.m-b-42 {
  margin-bottom: 42px;
}

.m-b-43 {
  margin-bottom: 43px;
}

.m-b-44 {
  margin-bottom: 44px;
}

.m-b-45 {
  margin-bottom: 45px;
}

.m-b-46 {
  margin-bottom: 46px;
}

.m-b-47 {
  margin-bottom: 47px;
}

.m-b-48 {
  margin-bottom: 48px;
}

.m-b-49 {
  margin-bottom: 49px;
}

.m-b-50 {
  margin-bottom: 50px;
}

.m-b-51 {
  margin-bottom: 51px;
}

.m-b-52 {
  margin-bottom: 52px;
}

.m-b-53 {
  margin-bottom: 53px;
}

.m-b-54 {
  margin-bottom: 54px;
}

.m-b-55 {
  margin-bottom: 55px;
}

.m-b-56 {
  margin-bottom: 56px;
}

.m-b-57 {
  margin-bottom: 57px;
}

.m-b-58 {
  margin-bottom: 58px;
}

.m-b-59 {
  margin-bottom: 59px;
}

.m-b-60 {
  margin-bottom: 60px;
}

.m-b-61 {
  margin-bottom: 61px;
}

.m-b-62 {
  margin-bottom: 62px;
}

.m-b-63 {
  margin-bottom: 63px;
}

.m-b-64 {
  margin-bottom: 64px;
}

.m-b-65 {
  margin-bottom: 65px;
}

.m-b-66 {
  margin-bottom: 66px;
}

.m-b-67 {
  margin-bottom: 67px;
}

.m-b-68 {
  margin-bottom: 68px;
}

.m-b-69 {
  margin-bottom: 69px;
}

.m-b-70 {
  margin-bottom: 70px;
}

.m-b-71 {
  margin-bottom: 71px;
}

.m-b-72 {
  margin-bottom: 72px;
}

.m-b-73 {
  margin-bottom: 73px;
}

.m-b-74 {
  margin-bottom: 74px;
}

.m-b-75 {
  margin-bottom: 75px;
}

.m-b-76 {
  margin-bottom: 76px;
}

.m-b-77 {
  margin-bottom: 77px;
}

.m-b-78 {
  margin-bottom: 78px;
}

.m-b-79 {
  margin-bottom: 79px;
}

.m-b-80 {
  margin-bottom: 80px;
}

.m-b-81 {
  margin-bottom: 81px;
}

.m-b-82 {
  margin-bottom: 82px;
}

.m-b-83 {
  margin-bottom: 83px;
}

.m-b-84 {
  margin-bottom: 84px;
}

.m-b-85 {
  margin-bottom: 85px;
}

.m-b-86 {
  margin-bottom: 86px;
}

.m-b-87 {
  margin-bottom: 87px;
}

.m-b-88 {
  margin-bottom: 88px;
}

.m-b-89 {
  margin-bottom: 89px;
}

.m-b-90 {
  margin-bottom: 90px;
}

.m-b-91 {
  margin-bottom: 91px;
}

.m-b-92 {
  margin-bottom: 92px;
}

.m-b-93 {
  margin-bottom: 93px;
}

.m-b-94 {
  margin-bottom: 94px;
}

.m-b-95 {
  margin-bottom: 95px;
}

.m-b-96 {
  margin-bottom: 96px;
}

.m-b-97 {
  margin-bottom: 97px;
}

.m-b-98 {
  margin-bottom: 98px;
}

.m-b-99 {
  margin-bottom: 99px;
}

.m-b-100 {
  margin-bottom: 100px;
}

.m-b-101 {
  margin-bottom: 101px;
}

.m-b-102 {
  margin-bottom: 102px;
}

.m-b-103 {
  margin-bottom: 103px;
}

.m-b-104 {
  margin-bottom: 104px;
}

.m-b-105 {
  margin-bottom: 105px;
}

.m-b-106 {
  margin-bottom: 106px;
}

.m-b-107 {
  margin-bottom: 107px;
}

.m-b-108 {
  margin-bottom: 108px;
}

.m-b-109 {
  margin-bottom: 109px;
}

.m-b-110 {
  margin-bottom: 110px;
}

.m-b-111 {
  margin-bottom: 111px;
}

.m-b-112 {
  margin-bottom: 112px;
}

.m-b-113 {
  margin-bottom: 113px;
}

.m-b-114 {
  margin-bottom: 114px;
}

.m-b-115 {
  margin-bottom: 115px;
}

.m-b-116 {
  margin-bottom: 116px;
}

.m-b-117 {
  margin-bottom: 117px;
}

.m-b-118 {
  margin-bottom: 118px;
}

.m-b-119 {
  margin-bottom: 119px;
}

.m-b-120 {
  margin-bottom: 120px;
}

.m-b-121 {
  margin-bottom: 121px;
}

.m-b-122 {
  margin-bottom: 122px;
}

.m-b-123 {
  margin-bottom: 123px;
}

.m-b-124 {
  margin-bottom: 124px;
}

.m-b-125 {
  margin-bottom: 125px;
}

.m-b-126 {
  margin-bottom: 126px;
}

.m-b-127 {
  margin-bottom: 127px;
}

.m-b-128 {
  margin-bottom: 128px;
}

.m-b-129 {
  margin-bottom: 129px;
}

.m-b-130 {
  margin-bottom: 130px;
}

.m-b-131 {
  margin-bottom: 131px;
}

.m-b-132 {
  margin-bottom: 132px;
}

.m-b-133 {
  margin-bottom: 133px;
}

.m-b-134 {
  margin-bottom: 134px;
}

.m-b-135 {
  margin-bottom: 135px;
}

.m-b-136 {
  margin-bottom: 136px;
}

.m-b-137 {
  margin-bottom: 137px;
}

.m-b-138 {
  margin-bottom: 138px;
}

.m-b-139 {
  margin-bottom: 139px;
}

.m-b-140 {
  margin-bottom: 140px;
}

.m-b-141 {
  margin-bottom: 141px;
}

.m-b-142 {
  margin-bottom: 142px;
}

.m-b-143 {
  margin-bottom: 143px;
}

.m-b-144 {
  margin-bottom: 144px;
}

.m-b-145 {
  margin-bottom: 145px;
}

.m-b-146 {
  margin-bottom: 146px;
}

.m-b-147 {
  margin-bottom: 147px;
}

.m-b-148 {
  margin-bottom: 148px;
}

.m-b-149 {
  margin-bottom: 149px;
}

.m-b-150 {
  margin-bottom: 150px;
}

.m-b-151 {
  margin-bottom: 151px;
}

.m-b-152 {
  margin-bottom: 152px;
}

.m-b-153 {
  margin-bottom: 153px;
}

.m-b-154 {
  margin-bottom: 154px;
}

.m-b-155 {
  margin-bottom: 155px;
}

.m-b-156 {
  margin-bottom: 156px;
}

.m-b-157 {
  margin-bottom: 157px;
}

.m-b-158 {
  margin-bottom: 158px;
}

.m-b-159 {
  margin-bottom: 159px;
}

.m-b-160 {
  margin-bottom: 160px;
}

.m-b-161 {
  margin-bottom: 161px;
}

.m-b-162 {
  margin-bottom: 162px;
}

.m-b-163 {
  margin-bottom: 163px;
}

.m-b-164 {
  margin-bottom: 164px;
}

.m-b-165 {
  margin-bottom: 165px;
}

.m-b-166 {
  margin-bottom: 166px;
}

.m-b-167 {
  margin-bottom: 167px;
}

.m-b-168 {
  margin-bottom: 168px;
}

.m-b-169 {
  margin-bottom: 169px;
}

.m-b-170 {
  margin-bottom: 170px;
}

.m-b-171 {
  margin-bottom: 171px;
}

.m-b-172 {
  margin-bottom: 172px;
}

.m-b-173 {
  margin-bottom: 173px;
}

.m-b-174 {
  margin-bottom: 174px;
}

.m-b-175 {
  margin-bottom: 175px;
}

.m-b-176 {
  margin-bottom: 176px;
}

.m-b-177 {
  margin-bottom: 177px;
}

.m-b-178 {
  margin-bottom: 178px;
}

.m-b-179 {
  margin-bottom: 179px;
}

.m-b-180 {
  margin-bottom: 180px;
}

.m-b-181 {
  margin-bottom: 181px;
}

.m-b-182 {
  margin-bottom: 182px;
}

.m-b-183 {
  margin-bottom: 183px;
}

.m-b-184 {
  margin-bottom: 184px;
}

.m-b-185 {
  margin-bottom: 185px;
}

.m-b-186 {
  margin-bottom: 186px;
}

.m-b-187 {
  margin-bottom: 187px;
}

.m-b-188 {
  margin-bottom: 188px;
}

.m-b-189 {
  margin-bottom: 189px;
}

.m-b-190 {
  margin-bottom: 190px;
}

.m-b-191 {
  margin-bottom: 191px;
}

.m-b-192 {
  margin-bottom: 192px;
}

.m-b-193 {
  margin-bottom: 193px;
}

.m-b-194 {
  margin-bottom: 194px;
}

.m-b-195 {
  margin-bottom: 195px;
}

.m-b-196 {
  margin-bottom: 196px;
}

.m-b-197 {
  margin-bottom: 197px;
}

.m-b-198 {
  margin-bottom: 198px;
}

.m-b-199 {
  margin-bottom: 199px;
}

.m-b-200 {
  margin-bottom: 200px;
}

.m-b-201 {
  margin-bottom: 201px;
}

.m-b-202 {
  margin-bottom: 202px;
}

.m-b-203 {
  margin-bottom: 203px;
}

.m-b-204 {
  margin-bottom: 204px;
}

.m-b-205 {
  margin-bottom: 205px;
}

.m-b-206 {
  margin-bottom: 206px;
}

.m-b-207 {
  margin-bottom: 207px;
}

.m-b-208 {
  margin-bottom: 208px;
}

.m-b-209 {
  margin-bottom: 209px;
}

.m-b-210 {
  margin-bottom: 210px;
}

.m-b-211 {
  margin-bottom: 211px;
}

.m-b-212 {
  margin-bottom: 212px;
}

.m-b-213 {
  margin-bottom: 213px;
}

.m-b-214 {
  margin-bottom: 214px;
}

.m-b-215 {
  margin-bottom: 215px;
}

.m-b-216 {
  margin-bottom: 216px;
}

.m-b-217 {
  margin-bottom: 217px;
}

.m-b-218 {
  margin-bottom: 218px;
}

.m-b-219 {
  margin-bottom: 219px;
}

.m-b-220 {
  margin-bottom: 220px;
}

.m-b-221 {
  margin-bottom: 221px;
}

.m-b-222 {
  margin-bottom: 222px;
}

.m-b-223 {
  margin-bottom: 223px;
}

.m-b-224 {
  margin-bottom: 224px;
}

.m-b-225 {
  margin-bottom: 225px;
}

.m-b-226 {
  margin-bottom: 226px;
}

.m-b-227 {
  margin-bottom: 227px;
}

.m-b-228 {
  margin-bottom: 228px;
}

.m-b-229 {
  margin-bottom: 229px;
}

.m-b-230 {
  margin-bottom: 230px;
}

.m-b-231 {
  margin-bottom: 231px;
}

.m-b-232 {
  margin-bottom: 232px;
}

.m-b-233 {
  margin-bottom: 233px;
}

.m-b-234 {
  margin-bottom: 234px;
}

.m-b-235 {
  margin-bottom: 235px;
}

.m-b-236 {
  margin-bottom: 236px;
}

.m-b-237 {
  margin-bottom: 237px;
}

.m-b-238 {
  margin-bottom: 238px;
}

.m-b-239 {
  margin-bottom: 239px;
}

.m-b-240 {
  margin-bottom: 240px;
}

.m-b-241 {
  margin-bottom: 241px;
}

.m-b-242 {
  margin-bottom: 242px;
}

.m-b-243 {
  margin-bottom: 243px;
}

.m-b-244 {
  margin-bottom: 244px;
}

.m-b-245 {
  margin-bottom: 245px;
}

.m-b-246 {
  margin-bottom: 246px;
}

.m-b-247 {
  margin-bottom: 247px;
}

.m-b-248 {
  margin-bottom: 248px;
}

.m-b-249 {
  margin-bottom: 249px;
}

.m-b-250 {
  margin-bottom: 250px;
}

.m-l-0 {
  margin-left: 0;
}

.m-l-1 {
  margin-left: 1px;
}

.m-l-2 {
  margin-left: 2px;
}

.m-l-3 {
  margin-left: 3px;
}

.m-l-4 {
  margin-left: 4px;
}

.m-l-5 {
  margin-left: 5px;
}

.m-l-6 {
  margin-left: 6px;
}

.m-l-7 {
  margin-left: 7px;
}

.m-l-8 {
  margin-left: 8px;
}

.m-l-9 {
  margin-left: 9px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-l-11 {
  margin-left: 11px;
}

.m-l-12 {
  margin-left: 12px;
}

.m-l-13 {
  margin-left: 13px;
}

.m-l-14 {
  margin-left: 14px;
}

.m-l-15 {
  margin-left: 15px;
}

.m-l-16 {
  margin-left: 16px;
}

.m-l-17 {
  margin-left: 17px;
}

.m-l-18 {
  margin-left: 18px;
}

.m-l-19 {
  margin-left: 19px;
}

.m-l-20 {
  margin-left: 20px;
}

.m-l-21 {
  margin-left: 21px;
}

.m-l-22 {
  margin-left: 22px;
}

.m-l-23 {
  margin-left: 23px;
}

.m-l-24 {
  margin-left: 24px;
}

.m-l-25 {
  margin-left: 25px;
}

.m-l-26 {
  margin-left: 26px;
}

.m-l-27 {
  margin-left: 27px;
}

.m-l-28 {
  margin-left: 28px;
}

.m-l-29 {
  margin-left: 29px;
}

.m-l-30 {
  margin-left: 30px;
}

.m-l-31 {
  margin-left: 31px;
}

.m-l-32 {
  margin-left: 32px;
}

.m-l-33 {
  margin-left: 33px;
}

.m-l-34 {
  margin-left: 34px;
}

.m-l-35 {
  margin-left: 35px;
}

.m-l-36 {
  margin-left: 36px;
}

.m-l-37 {
  margin-left: 37px;
}

.m-l-38 {
  margin-left: 38px;
}

.m-l-39 {
  margin-left: 39px;
}

.m-l-40 {
  margin-left: 40px;
}

.m-l-41 {
  margin-left: 41px;
}

.m-l-42 {
  margin-left: 42px;
}

.m-l-43 {
  margin-left: 43px;
}

.m-l-44 {
  margin-left: 44px;
}

.m-l-45 {
  margin-left: 45px;
}

.m-l-46 {
  margin-left: 46px;
}

.m-l-47 {
  margin-left: 47px;
}

.m-l-48 {
  margin-left: 48px;
}

.m-l-49 {
  margin-left: 49px;
}

.m-l-50 {
  margin-left: 50px;
}

.m-l-51 {
  margin-left: 51px;
}

.m-l-52 {
  margin-left: 52px;
}

.m-l-53 {
  margin-left: 53px;
}

.m-l-54 {
  margin-left: 54px;
}

.m-l-55 {
  margin-left: 55px;
}

.m-l-56 {
  margin-left: 56px;
}

.m-l-57 {
  margin-left: 57px;
}

.m-l-58 {
  margin-left: 58px;
}

.m-l-59 {
  margin-left: 59px;
}

.m-l-60 {
  margin-left: 60px;
}

.m-l-61 {
  margin-left: 61px;
}

.m-l-62 {
  margin-left: 62px;
}

.m-l-63 {
  margin-left: 63px;
}

.m-l-64 {
  margin-left: 64px;
}

.m-l-65 {
  margin-left: 65px;
}

.m-l-66 {
  margin-left: 66px;
}

.m-l-67 {
  margin-left: 67px;
}

.m-l-68 {
  margin-left: 68px;
}

.m-l-69 {
  margin-left: 69px;
}

.m-l-70 {
  margin-left: 70px;
}

.m-l-71 {
  margin-left: 71px;
}

.m-l-72 {
  margin-left: 72px;
}

.m-l-73 {
  margin-left: 73px;
}

.m-l-74 {
  margin-left: 74px;
}

.m-l-75 {
  margin-left: 75px;
}

.m-l-76 {
  margin-left: 76px;
}

.m-l-77 {
  margin-left: 77px;
}

.m-l-78 {
  margin-left: 78px;
}

.m-l-79 {
  margin-left: 79px;
}

.m-l-80 {
  margin-left: 80px;
}

.m-l-81 {
  margin-left: 81px;
}

.m-l-82 {
  margin-left: 82px;
}

.m-l-83 {
  margin-left: 83px;
}

.m-l-84 {
  margin-left: 84px;
}

.m-l-85 {
  margin-left: 85px;
}

.m-l-86 {
  margin-left: 86px;
}

.m-l-87 {
  margin-left: 87px;
}

.m-l-88 {
  margin-left: 88px;
}

.m-l-89 {
  margin-left: 89px;
}

.m-l-90 {
  margin-left: 90px;
}

.m-l-91 {
  margin-left: 91px;
}

.m-l-92 {
  margin-left: 92px;
}

.m-l-93 {
  margin-left: 93px;
}

.m-l-94 {
  margin-left: 94px;
}

.m-l-95 {
  margin-left: 95px;
}

.m-l-96 {
  margin-left: 96px;
}

.m-l-97 {
  margin-left: 97px;
}

.m-l-98 {
  margin-left: 98px;
}

.m-l-99 {
  margin-left: 99px;
}

.m-l-100 {
  margin-left: 100px;
}

.m-l-101 {
  margin-left: 101px;
}

.m-l-102 {
  margin-left: 102px;
}

.m-l-103 {
  margin-left: 103px;
}

.m-l-104 {
  margin-left: 104px;
}

.m-l-105 {
  margin-left: 105px;
}

.m-l-106 {
  margin-left: 106px;
}

.m-l-107 {
  margin-left: 107px;
}

.m-l-108 {
  margin-left: 108px;
}

.m-l-109 {
  margin-left: 109px;
}

.m-l-110 {
  margin-left: 110px;
}

.m-l-111 {
  margin-left: 111px;
}

.m-l-112 {
  margin-left: 112px;
}

.m-l-113 {
  margin-left: 113px;
}

.m-l-114 {
  margin-left: 114px;
}

.m-l-115 {
  margin-left: 115px;
}

.m-l-116 {
  margin-left: 116px;
}

.m-l-117 {
  margin-left: 117px;
}

.m-l-118 {
  margin-left: 118px;
}

.m-l-119 {
  margin-left: 119px;
}

.m-l-120 {
  margin-left: 120px;
}

.m-l-121 {
  margin-left: 121px;
}

.m-l-122 {
  margin-left: 122px;
}

.m-l-123 {
  margin-left: 123px;
}

.m-l-124 {
  margin-left: 124px;
}

.m-l-125 {
  margin-left: 125px;
}

.m-l-126 {
  margin-left: 126px;
}

.m-l-127 {
  margin-left: 127px;
}

.m-l-128 {
  margin-left: 128px;
}

.m-l-129 {
  margin-left: 129px;
}

.m-l-130 {
  margin-left: 130px;
}

.m-l-131 {
  margin-left: 131px;
}

.m-l-132 {
  margin-left: 132px;
}

.m-l-133 {
  margin-left: 133px;
}

.m-l-134 {
  margin-left: 134px;
}

.m-l-135 {
  margin-left: 135px;
}

.m-l-136 {
  margin-left: 136px;
}

.m-l-137 {
  margin-left: 137px;
}

.m-l-138 {
  margin-left: 138px;
}

.m-l-139 {
  margin-left: 139px;
}

.m-l-140 {
  margin-left: 140px;
}

.m-l-141 {
  margin-left: 141px;
}

.m-l-142 {
  margin-left: 142px;
}

.m-l-143 {
  margin-left: 143px;
}

.m-l-144 {
  margin-left: 144px;
}

.m-l-145 {
  margin-left: 145px;
}

.m-l-146 {
  margin-left: 146px;
}

.m-l-147 {
  margin-left: 147px;
}

.m-l-148 {
  margin-left: 148px;
}

.m-l-149 {
  margin-left: 149px;
}

.m-l-150 {
  margin-left: 150px;
}

.m-l-151 {
  margin-left: 151px;
}

.m-l-152 {
  margin-left: 152px;
}

.m-l-153 {
  margin-left: 153px;
}

.m-l-154 {
  margin-left: 154px;
}

.m-l-155 {
  margin-left: 155px;
}

.m-l-156 {
  margin-left: 156px;
}

.m-l-157 {
  margin-left: 157px;
}

.m-l-158 {
  margin-left: 158px;
}

.m-l-159 {
  margin-left: 159px;
}

.m-l-160 {
  margin-left: 160px;
}

.m-l-161 {
  margin-left: 161px;
}

.m-l-162 {
  margin-left: 162px;
}

.m-l-163 {
  margin-left: 163px;
}

.m-l-164 {
  margin-left: 164px;
}

.m-l-165 {
  margin-left: 165px;
}

.m-l-166 {
  margin-left: 166px;
}

.m-l-167 {
  margin-left: 167px;
}

.m-l-168 {
  margin-left: 168px;
}

.m-l-169 {
  margin-left: 169px;
}

.m-l-170 {
  margin-left: 170px;
}

.m-l-171 {
  margin-left: 171px;
}

.m-l-172 {
  margin-left: 172px;
}

.m-l-173 {
  margin-left: 173px;
}

.m-l-174 {
  margin-left: 174px;
}

.m-l-175 {
  margin-left: 175px;
}

.m-l-176 {
  margin-left: 176px;
}

.m-l-177 {
  margin-left: 177px;
}

.m-l-178 {
  margin-left: 178px;
}

.m-l-179 {
  margin-left: 179px;
}

.m-l-180 {
  margin-left: 180px;
}

.m-l-181 {
  margin-left: 181px;
}

.m-l-182 {
  margin-left: 182px;
}

.m-l-183 {
  margin-left: 183px;
}

.m-l-184 {
  margin-left: 184px;
}

.m-l-185 {
  margin-left: 185px;
}

.m-l-186 {
  margin-left: 186px;
}

.m-l-187 {
  margin-left: 187px;
}

.m-l-188 {
  margin-left: 188px;
}

.m-l-189 {
  margin-left: 189px;
}

.m-l-190 {
  margin-left: 190px;
}

.m-l-191 {
  margin-left: 191px;
}

.m-l-192 {
  margin-left: 192px;
}

.m-l-193 {
  margin-left: 193px;
}

.m-l-194 {
  margin-left: 194px;
}

.m-l-195 {
  margin-left: 195px;
}

.m-l-196 {
  margin-left: 196px;
}

.m-l-197 {
  margin-left: 197px;
}

.m-l-198 {
  margin-left: 198px;
}

.m-l-199 {
  margin-left: 199px;
}

.m-l-200 {
  margin-left: 200px;
}

.m-l-201 {
  margin-left: 201px;
}

.m-l-202 {
  margin-left: 202px;
}

.m-l-203 {
  margin-left: 203px;
}

.m-l-204 {
  margin-left: 204px;
}

.m-l-205 {
  margin-left: 205px;
}

.m-l-206 {
  margin-left: 206px;
}

.m-l-207 {
  margin-left: 207px;
}

.m-l-208 {
  margin-left: 208px;
}

.m-l-209 {
  margin-left: 209px;
}

.m-l-210 {
  margin-left: 210px;
}

.m-l-211 {
  margin-left: 211px;
}

.m-l-212 {
  margin-left: 212px;
}

.m-l-213 {
  margin-left: 213px;
}

.m-l-214 {
  margin-left: 214px;
}

.m-l-215 {
  margin-left: 215px;
}

.m-l-216 {
  margin-left: 216px;
}

.m-l-217 {
  margin-left: 217px;
}

.m-l-218 {
  margin-left: 218px;
}

.m-l-219 {
  margin-left: 219px;
}

.m-l-220 {
  margin-left: 220px;
}

.m-l-221 {
  margin-left: 221px;
}

.m-l-222 {
  margin-left: 222px;
}

.m-l-223 {
  margin-left: 223px;
}

.m-l-224 {
  margin-left: 224px;
}

.m-l-225 {
  margin-left: 225px;
}

.m-l-226 {
  margin-left: 226px;
}

.m-l-227 {
  margin-left: 227px;
}

.m-l-228 {
  margin-left: 228px;
}

.m-l-229 {
  margin-left: 229px;
}

.m-l-230 {
  margin-left: 230px;
}

.m-l-231 {
  margin-left: 231px;
}

.m-l-232 {
  margin-left: 232px;
}

.m-l-233 {
  margin-left: 233px;
}

.m-l-234 {
  margin-left: 234px;
}

.m-l-235 {
  margin-left: 235px;
}

.m-l-236 {
  margin-left: 236px;
}

.m-l-237 {
  margin-left: 237px;
}

.m-l-238 {
  margin-left: 238px;
}

.m-l-239 {
  margin-left: 239px;
}

.m-l-240 {
  margin-left: 240px;
}

.m-l-241 {
  margin-left: 241px;
}

.m-l-242 {
  margin-left: 242px;
}

.m-l-243 {
  margin-left: 243px;
}

.m-l-244 {
  margin-left: 244px;
}

.m-l-245 {
  margin-left: 245px;
}

.m-l-246 {
  margin-left: 246px;
}

.m-l-247 {
  margin-left: 247px;
}

.m-l-248 {
  margin-left: 248px;
}

.m-l-249 {
  margin-left: 249px;
}

.m-l-250 {
  margin-left: 250px;
}

.m-r-0 {
  margin-right: 0;
}

.m-r-1 {
  margin-right: 1px;
}

.m-r-2 {
  margin-right: 2px;
}

.m-r-3 {
  margin-right: 3px;
}

.m-r-4 {
  margin-right: 4px;
}

.m-r-5 {
  margin-right: 5px;
}

.m-r-6 {
  margin-right: 6px;
}

.m-r-7 {
  margin-right: 7px;
}

.m-r-8 {
  margin-right: 8px;
}

.m-r-9 {
  margin-right: 9px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-r-11 {
  margin-right: 11px;
}

.m-r-12 {
  margin-right: 12px;
}

.m-r-13 {
  margin-right: 13px;
}

.m-r-14 {
  margin-right: 14px;
}

.m-r-15 {
  margin-right: 15px;
}

.m-r-16 {
  margin-right: 16px;
}

.m-r-17 {
  margin-right: 17px;
}

.m-r-18 {
  margin-right: 18px;
}

.m-r-19 {
  margin-right: 19px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-r-21 {
  margin-right: 21px;
}

.m-r-22 {
  margin-right: 22px;
}

.m-r-23 {
  margin-right: 23px;
}

.m-r-24 {
  margin-right: 24px;
}

.m-r-25 {
  margin-right: 25px;
}

.m-r-26 {
  margin-right: 26px;
}

.m-r-27 {
  margin-right: 27px;
}

.m-r-28 {
  margin-right: 28px;
}

.m-r-29 {
  margin-right: 29px;
}

.m-r-30 {
  margin-right: 30px;
}

.m-r-31 {
  margin-right: 31px;
}

.m-r-32 {
  margin-right: 32px;
}

.m-r-33 {
  margin-right: 33px;
}

.m-r-34 {
  margin-right: 34px;
}

.m-r-35 {
  margin-right: 35px;
}

.m-r-36 {
  margin-right: 36px;
}

.m-r-37 {
  margin-right: 37px;
}

.m-r-38 {
  margin-right: 38px;
}

.m-r-39 {
  margin-right: 39px;
}

.m-r-40 {
  margin-right: 40px;
}

.m-r-41 {
  margin-right: 41px;
}

.m-r-42 {
  margin-right: 42px;
}

.m-r-43 {
  margin-right: 43px;
}

.m-r-44 {
  margin-right: 44px;
}

.m-r-45 {
  margin-right: 45px;
}

.m-r-46 {
  margin-right: 46px;
}

.m-r-47 {
  margin-right: 47px;
}

.m-r-48 {
  margin-right: 48px;
}

.m-r-49 {
  margin-right: 49px;
}

.m-r-50 {
  margin-right: 50px;
}

.m-r-51 {
  margin-right: 51px;
}

.m-r-52 {
  margin-right: 52px;
}

.m-r-53 {
  margin-right: 53px;
}

.m-r-54 {
  margin-right: 54px;
}

.m-r-55 {
  margin-right: 55px;
}

.m-r-56 {
  margin-right: 56px;
}

.m-r-57 {
  margin-right: 57px;
}

.m-r-58 {
  margin-right: 58px;
}

.m-r-59 {
  margin-right: 59px;
}

.m-r-60 {
  margin-right: 60px;
}

.m-r-61 {
  margin-right: 61px;
}

.m-r-62 {
  margin-right: 62px;
}

.m-r-63 {
  margin-right: 63px;
}

.m-r-64 {
  margin-right: 64px;
}

.m-r-65 {
  margin-right: 65px;
}

.m-r-66 {
  margin-right: 66px;
}

.m-r-67 {
  margin-right: 67px;
}

.m-r-68 {
  margin-right: 68px;
}

.m-r-69 {
  margin-right: 69px;
}

.m-r-70 {
  margin-right: 70px;
}

.m-r-71 {
  margin-right: 71px;
}

.m-r-72 {
  margin-right: 72px;
}

.m-r-73 {
  margin-right: 73px;
}

.m-r-74 {
  margin-right: 74px;
}

.m-r-75 {
  margin-right: 75px;
}

.m-r-76 {
  margin-right: 76px;
}

.m-r-77 {
  margin-right: 77px;
}

.m-r-78 {
  margin-right: 78px;
}

.m-r-79 {
  margin-right: 79px;
}

.m-r-80 {
  margin-right: 80px;
}

.m-r-81 {
  margin-right: 81px;
}

.m-r-82 {
  margin-right: 82px;
}

.m-r-83 {
  margin-right: 83px;
}

.m-r-84 {
  margin-right: 84px;
}

.m-r-85 {
  margin-right: 85px;
}

.m-r-86 {
  margin-right: 86px;
}

.m-r-87 {
  margin-right: 87px;
}

.m-r-88 {
  margin-right: 88px;
}

.m-r-89 {
  margin-right: 89px;
}

.m-r-90 {
  margin-right: 90px;
}

.m-r-91 {
  margin-right: 91px;
}

.m-r-92 {
  margin-right: 92px;
}

.m-r-93 {
  margin-right: 93px;
}

.m-r-94 {
  margin-right: 94px;
}

.m-r-95 {
  margin-right: 95px;
}

.m-r-96 {
  margin-right: 96px;
}

.m-r-97 {
  margin-right: 97px;
}

.m-r-98 {
  margin-right: 98px;
}

.m-r-99 {
  margin-right: 99px;
}

.m-r-100 {
  margin-right: 100px;
}

.m-r-101 {
  margin-right: 101px;
}

.m-r-102 {
  margin-right: 102px;
}

.m-r-103 {
  margin-right: 103px;
}

.m-r-104 {
  margin-right: 104px;
}

.m-r-105 {
  margin-right: 105px;
}

.m-r-106 {
  margin-right: 106px;
}

.m-r-107 {
  margin-right: 107px;
}

.m-r-108 {
  margin-right: 108px;
}

.m-r-109 {
  margin-right: 109px;
}

.m-r-110 {
  margin-right: 110px;
}

.m-r-111 {
  margin-right: 111px;
}

.m-r-112 {
  margin-right: 112px;
}

.m-r-113 {
  margin-right: 113px;
}

.m-r-114 {
  margin-right: 114px;
}

.m-r-115 {
  margin-right: 115px;
}

.m-r-116 {
  margin-right: 116px;
}

.m-r-117 {
  margin-right: 117px;
}

.m-r-118 {
  margin-right: 118px;
}

.m-r-119 {
  margin-right: 119px;
}

.m-r-120 {
  margin-right: 120px;
}

.m-r-121 {
  margin-right: 121px;
}

.m-r-122 {
  margin-right: 122px;
}

.m-r-123 {
  margin-right: 123px;
}

.m-r-124 {
  margin-right: 124px;
}

.m-r-125 {
  margin-right: 125px;
}

.m-r-126 {
  margin-right: 126px;
}

.m-r-127 {
  margin-right: 127px;
}

.m-r-128 {
  margin-right: 128px;
}

.m-r-129 {
  margin-right: 129px;
}

.m-r-130 {
  margin-right: 130px;
}

.m-r-131 {
  margin-right: 131px;
}

.m-r-132 {
  margin-right: 132px;
}

.m-r-133 {
  margin-right: 133px;
}

.m-r-134 {
  margin-right: 134px;
}

.m-r-135 {
  margin-right: 135px;
}

.m-r-136 {
  margin-right: 136px;
}

.m-r-137 {
  margin-right: 137px;
}

.m-r-138 {
  margin-right: 138px;
}

.m-r-139 {
  margin-right: 139px;
}

.m-r-140 {
  margin-right: 140px;
}

.m-r-141 {
  margin-right: 141px;
}

.m-r-142 {
  margin-right: 142px;
}

.m-r-143 {
  margin-right: 143px;
}

.m-r-144 {
  margin-right: 144px;
}

.m-r-145 {
  margin-right: 145px;
}

.m-r-146 {
  margin-right: 146px;
}

.m-r-147 {
  margin-right: 147px;
}

.m-r-148 {
  margin-right: 148px;
}

.m-r-149 {
  margin-right: 149px;
}

.m-r-150 {
  margin-right: 150px;
}

.m-r-151 {
  margin-right: 151px;
}

.m-r-152 {
  margin-right: 152px;
}

.m-r-153 {
  margin-right: 153px;
}

.m-r-154 {
  margin-right: 154px;
}

.m-r-155 {
  margin-right: 155px;
}

.m-r-156 {
  margin-right: 156px;
}

.m-r-157 {
  margin-right: 157px;
}

.m-r-158 {
  margin-right: 158px;
}

.m-r-159 {
  margin-right: 159px;
}

.m-r-160 {
  margin-right: 160px;
}

.m-r-161 {
  margin-right: 161px;
}

.m-r-162 {
  margin-right: 162px;
}

.m-r-163 {
  margin-right: 163px;
}

.m-r-164 {
  margin-right: 164px;
}

.m-r-165 {
  margin-right: 165px;
}

.m-r-166 {
  margin-right: 166px;
}

.m-r-167 {
  margin-right: 167px;
}

.m-r-168 {
  margin-right: 168px;
}

.m-r-169 {
  margin-right: 169px;
}

.m-r-170 {
  margin-right: 170px;
}

.m-r-171 {
  margin-right: 171px;
}

.m-r-172 {
  margin-right: 172px;
}

.m-r-173 {
  margin-right: 173px;
}

.m-r-174 {
  margin-right: 174px;
}

.m-r-175 {
  margin-right: 175px;
}

.m-r-176 {
  margin-right: 176px;
}

.m-r-177 {
  margin-right: 177px;
}

.m-r-178 {
  margin-right: 178px;
}

.m-r-179 {
  margin-right: 179px;
}

.m-r-180 {
  margin-right: 180px;
}

.m-r-181 {
  margin-right: 181px;
}

.m-r-182 {
  margin-right: 182px;
}

.m-r-183 {
  margin-right: 183px;
}

.m-r-184 {
  margin-right: 184px;
}

.m-r-185 {
  margin-right: 185px;
}

.m-r-186 {
  margin-right: 186px;
}

.m-r-187 {
  margin-right: 187px;
}

.m-r-188 {
  margin-right: 188px;
}

.m-r-189 {
  margin-right: 189px;
}

.m-r-190 {
  margin-right: 190px;
}

.m-r-191 {
  margin-right: 191px;
}

.m-r-192 {
  margin-right: 192px;
}

.m-r-193 {
  margin-right: 193px;
}

.m-r-194 {
  margin-right: 194px;
}

.m-r-195 {
  margin-right: 195px;
}

.m-r-196 {
  margin-right: 196px;
}

.m-r-197 {
  margin-right: 197px;
}

.m-r-198 {
  margin-right: 198px;
}

.m-r-199 {
  margin-right: 199px;
}

.m-r-200 {
  margin-right: 200px;
}

.m-r-201 {
  margin-right: 201px;
}

.m-r-202 {
  margin-right: 202px;
}

.m-r-203 {
  margin-right: 203px;
}

.m-r-204 {
  margin-right: 204px;
}

.m-r-205 {
  margin-right: 205px;
}

.m-r-206 {
  margin-right: 206px;
}

.m-r-207 {
  margin-right: 207px;
}

.m-r-208 {
  margin-right: 208px;
}

.m-r-209 {
  margin-right: 209px;
}

.m-r-210 {
  margin-right: 210px;
}

.m-r-211 {
  margin-right: 211px;
}

.m-r-212 {
  margin-right: 212px;
}

.m-r-213 {
  margin-right: 213px;
}

.m-r-214 {
  margin-right: 214px;
}

.m-r-215 {
  margin-right: 215px;
}

.m-r-216 {
  margin-right: 216px;
}

.m-r-217 {
  margin-right: 217px;
}

.m-r-218 {
  margin-right: 218px;
}

.m-r-219 {
  margin-right: 219px;
}

.m-r-220 {
  margin-right: 220px;
}

.m-r-221 {
  margin-right: 221px;
}

.m-r-222 {
  margin-right: 222px;
}

.m-r-223 {
  margin-right: 223px;
}

.m-r-224 {
  margin-right: 224px;
}

.m-r-225 {
  margin-right: 225px;
}

.m-r-226 {
  margin-right: 226px;
}

.m-r-227 {
  margin-right: 227px;
}

.m-r-228 {
  margin-right: 228px;
}

.m-r-229 {
  margin-right: 229px;
}

.m-r-230 {
  margin-right: 230px;
}

.m-r-231 {
  margin-right: 231px;
}

.m-r-232 {
  margin-right: 232px;
}

.m-r-233 {
  margin-right: 233px;
}

.m-r-234 {
  margin-right: 234px;
}

.m-r-235 {
  margin-right: 235px;
}

.m-r-236 {
  margin-right: 236px;
}

.m-r-237 {
  margin-right: 237px;
}

.m-r-238 {
  margin-right: 238px;
}

.m-r-239 {
  margin-right: 239px;
}

.m-r-240 {
  margin-right: 240px;
}

.m-r-241 {
  margin-right: 241px;
}

.m-r-242 {
  margin-right: 242px;
}

.m-r-243 {
  margin-right: 243px;
}

.m-r-244 {
  margin-right: 244px;
}

.m-r-245 {
  margin-right: 245px;
}

.m-r-246 {
  margin-right: 246px;
}

.m-r-247 {
  margin-right: 247px;
}

.m-r-248 {
  margin-right: 248px;
}

.m-r-249 {
  margin-right: 249px;
}

.m-r-250 {
  margin-right: 250px;
}

.m-l-auto {
  margin-left: auto;
}

.m-r-auto {
  margin-right: auto;
}

.m-lr-auto {
  margin-left: auto;
  margin-right: auto;
}

.color-white {
  color: #fff;
}

.color-black {
  color: #000;
}

.lh-1-0 {
  line-height: 1;
}

.lh-1-1 {
  line-height: 1.1;
}

.lh-1-2 {
  line-height: 1.2;
}

.lh-1-3 {
  line-height: 1.3;
}

.lh-1-4 {
  line-height: 1.4;
}

.lh-1-5 {
  line-height: 1.5;
}

.lh-1-6 {
  line-height: 1.6;
}

.lh-1-7 {
  line-height: 1.7;
}

.lh-1-8 {
  line-height: 1.8;
}

.lh-1-9 {
  line-height: 1.9;
}

.lh-2-0 {
  line-height: 2;
}

.lh-2-1 {
  line-height: 2.1;
}

.lh-2-2 {
  line-height: 2.2;
}

.lh-2-3 {
  line-height: 2.3;
}

.lh-2-4 {
  line-height: 2.4;
}

.lh-2-5 {
  line-height: 2.5;
}

.lh-2-6 {
  line-height: 2.6;
}

.lh-2-7 {
  line-height: 2.7;
}

.lh-2-8 {
  line-height: 2.8;
}

.lh-2-9 {
  line-height: 2.9;
}

.lh-3-0 {
  line-height: 3;
}

.txt-center {
  text-align: center;
}

.txt-left {
  text-align: left;
}

.txt-right {
  text-align: right;
}

.txt-middle {
  vertical-align: middle;
}

.size-full {
  width: 100%;
  height: 100%;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.max-w-full {
  max-width: 100%;
}

.max-h-full {
  max-height: 100%;
}

.min-w-full {
  min-width: 100%;
}

.min-h-full {
  min-height: 100%;
}

.bgwhite {
  background-color: #fff;
}

.bgblack {
  background-color: #000;
}

.hov-img-zoom {
  display: block;
  overflow: hidden;
}

.hov-img-zoom img {
  width: 100%;
  -webkit-transition: all 0.6s;
  -o-transition: all 0.6s;
  -moz-transition: all 0.6s;
  transition: all 0.6s;
}

.hov-img-zoom:hover img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.pointer {
  cursor: pointer;
}

.op-00 {
  opacity: 0;
}

.op-01 {
  opacity: 0.1;
}

.op-02 {
  opacity: 0.2;
}

.op-03 {
  opacity: 0.3;
}

.op-04 {
  opacity: 0.4;
}

.op-05 {
  opacity: 0.5;
}

.op-06 {
  opacity: 0.6;
}

.op-07 {
  opacity: 0.7;
}

.op-08 {
  opacity: 0.8;
}

.op-09 {
  opacity: 0.9;
}

.op-10 {
  opacity: 1;
}

.wrap-pic-w img {
  width: 100%;
}

.wrap-pic-max-w img {
  max-width: 100%;
}

.wrap-pic-h img {
  height: 100%;
}

.wrap-pic-max-h img {
  max-height: 100%;
}

.wrap-pic-cir {
  border-radius: 50%;
  overflow: hidden;
}

.wrap-pic-cir img {
  width: 100%;
}

.bo-cir {
  border-radius: 50%;
}

.of-hidden {
  overflow: hidden;
}

.visible-false {
  visibility: hidden;
}

.visible-true {
  visibility: visible;
}

.trans-01 {
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  -moz-transition: all 0.1s;
  transition: all 0.1s;
}

.trans-02 {
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}

.trans-03 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.trans-04 {
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.trans-05 {
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.trans-06 {
  -webkit-transition: all 0.6s;
  -o-transition: all 0.6s;
  -moz-transition: all 0.6s;
  transition: all 0.6s;
}

.trans-07 {
  -webkit-transition: all 0.7s;
  -o-transition: all 0.7s;
  -moz-transition: all 0.7s;
  transition: all 0.7s;
}

.trans-08 {
  -webkit-transition: all 0.8s;
  -o-transition: all 0.8s;
  -moz-transition: all 0.8s;
  transition: all 0.8s;
}

.trans-09 {
  -webkit-transition: all 0.9s;
  -o-transition: all 0.9s;
  -moz-transition: all 0.9s;
  transition: all 0.9s;
}

.trans-10 {
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  -moz-transition: all 1s;
  transition: all 1s;
}

.dis-none {
  display: none;
}

.dis-block {
  display: block;
}

.dis-inline {
  display: inline;
}

.dis-inline-block {
  display: inline-block;
}

.flex-w,
.flex-l,
.flex-r,
.flex-c,
.flex-sa,
.flex-sb,
.flex-t,
.flex-b,
.flex-m,
.flex-str,
.flex-c-m,
.flex-c-t,
.flex-c-b,
.flex-c-str,
.flex-l-m,
.flex-r-m,
.flex-sa-m,
.flex-sb-m,
.flex-col-l,
.flex-col-r,
.flex-col-c,
.flex-col-str,
.flex-col-t,
.flex-col-b,
.flex-col-m,
.flex-col-sb,
.flex-col-sa,
.flex-col-c-m,
.flex-col-l-m,
.flex-col-r-m,
.flex-col-str-m,
.flex-col-c-t,
.flex-col-c-b,
.flex-col-c-sb,
.flex-col-c-sa,
.flex-row,
.flex-row-rev,
.flex-col,
.flex-col-rev,
.dis-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.pos-relative {
  position: relative;
}

.pos-absolute {
  position: absolute;
}

.pos-fixed {
  position: fixed;
}

.float-l {
  float: left;
}

.float-r {
  float: right;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.top-auto {
  top: auto;
}

.bottom-auto {
  bottom: auto;
}

.left-auto {
  left: auto;
}

.right-auto {
  right: auto;
}

.flex-w {
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex-l {
  justify-content: flex-start;
}

.flex-r {
  justify-content: flex-end;
}

.flex-c {
  justify-content: center;
}

.flex-sa {
  justify-content: space-around;
}

.flex-sb {
  justify-content: space-between;
}

.flex-t {
  -ms-align-items: flex-start;
  align-items: flex-start;
}

.flex-b {
  -ms-align-items: flex-end;
  align-items: flex-end;
}

.flex-m {
  -ms-align-items: center;
  align-items: center;
}

.flex-str {
  -ms-align-items: stretch;
  align-items: stretch;
}

.flex-c-m {
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
}

.flex-c-t {
  justify-content: center;
  -ms-align-items: flex-start;
  align-items: flex-start;
}

.flex-c-b {
  justify-content: center;
  -ms-align-items: flex-end;
  align-items: flex-end;
}

.flex-c-str {
  justify-content: center;
  -ms-align-items: stretch;
  align-items: stretch;
}

.flex-l-m {
  justify-content: flex-start;
  -ms-align-items: center;
  align-items: center;
}

.flex-r-m {
  justify-content: flex-end;
  -ms-align-items: center;
  align-items: center;
}

.flex-sa-m {
  justify-content: space-around;
  -ms-align-items: center;
  align-items: center;
}

.flex-sb-m {
  justify-content: space-between;
  -ms-align-items: center;
  align-items: center;
}

.flex-col-l {
  -ms-align-items: flex-start;
  align-items: flex-start;
}

.flex-col-r {
  -ms-align-items: flex-end;
  align-items: flex-end;
}

.flex-col-c {
  -ms-align-items: center;
  align-items: center;
}

.flex-col-str {
  -ms-align-items: stretch;
  align-items: stretch;
}

.flex-col-t {
  justify-content: flex-start;
}

.flex-col-b {
  justify-content: flex-end;
}

.flex-col-m {
  justify-content: center;
}

.flex-col-sb {
  justify-content: space-between;
}

.flex-col-sa {
  justify-content: space-around;
}

.flex-col-c-m {
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
}

.flex-col-l-m {
  -ms-align-items: flex-start;
  align-items: flex-start;
  justify-content: center;
}

.flex-col-r-m {
  -ms-align-items: flex-end;
  align-items: flex-end;
  justify-content: center;
}

.flex-col-str-m {
  -ms-align-items: stretch;
  align-items: stretch;
  justify-content: center;
}

.flex-col-c-t {
  justify-content: flex-start;
  -ms-align-items: center;
  align-items: center;
}

.flex-col-c-b {
  justify-content: flex-end;
  -ms-align-items: center;
  align-items: center;
}

.flex-col-c-sb {
  justify-content: space-between;
  -ms-align-items: center;
  align-items: center;
}

.flex-col-c-sa {
  justify-content: space-around;
  -ms-align-items: center;
  align-items: center;
}

.flex-row {
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  -o-flex-direction: row;
  flex-direction: row;
}

.flex-row-rev {
  -webkit-flex-direction: row-reverse;
  -moz-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  -o-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.flex-col-l,
.flex-col-r,
.flex-col-c,
.flex-col-str,
.flex-col-t,
.flex-col-b,
.flex-col-m,
.flex-col-sb,
.flex-col-sa,
.flex-col-c-m,
.flex-col-l-m,
.flex-col-r-m,
.flex-col-str-m,
.flex-col-c-t,
.flex-col-c-b,
.flex-col-c-sb,
.flex-col-c-sa,
.flex-col {
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
}

.flex-col-rev {
  -webkit-flex-direction: column-reverse;
  -moz-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  -o-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.ab-c-m {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.ab-c-t {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.ab-c-b {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.ab-l-m {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ab-r-m {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ab-t-l {
  position: absolute;
  left: 0;
  top: 0;
}

.ab-t-r {
  position: absolute;
  right: 0;
  top: 0;
}

.ab-b-l {
  position: absolute;
  left: 0;
  bottom: 0;
}

.ab-b-r {
  position: absolute;
  right: 0;
  bottom: 0;
}

// reset.css
fieldset,
img {
  border: 0;
}

a {
  color: #000;
}

a:hover {
  color: #ababab;
  text-decoration: none;
}

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}

.clearfix:after {
  clear: both;
}

::-moz-selection {
  background: #3b3b3b;
  color: #818181;
}

::selection {
  background: #3b3b3b;
  color: #818181;
}

input,
textarea,
select,
button {
  font-family: 'AveNext', sans-serif;
}

textarea {
  -webkit-appearance: none;
}

input:focus {
  color: #000;
  background-color: transparent;
}

input[type='text'],
input[type='button'],
input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='submit'],
input[type='tel'],
input[type='time'] {
  -webkit-appearance: none;
}

textarea:focus {
  color: #000;
  background-color: transparent;
}

address,
caption,
cite,
code,
dfn,
th,
var {
  font-style: normal;
  font-weight: 400;
}

ol,
ul {
  list-style: none;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

q:before,
q:after {
  content: '';
}

abbr,
acronym {
  border: 0;
}

img {
  max-width: 100%;
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: 700;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
  float: left;
  width: 100%;
}

input,
select {
  vertical-align: middle;
}

p {
  padding-bottom: 20px;
}

.chromeframe {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ccc;
  z-index: 9999;
}

.chrome_box {
  left: 50%;
  color: #000;
  font-size: 20px;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.yazitext ul {
  float: left;
  width: 100%;
  padding-bottom: 20px;
}

.yazitext ul li {
  padding-left: 19px;
  position: relative;
}

.yazitext ul li::after {
  content: '•';
  left: 0;
  position: absolute;
  top: 0;
}

.search_ul li::after {
  display: none;
}

input:focus,
a:focus,
textarea:focus {
  outline: 0;
}

// top.css
.cd-top {
  display: inline-block;
  height: 40px;
  width: 40px;
  position: fixed;
  bottom: 79px;
  right: 27px;
  box-shadow: 0 0 10px #000;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  background: #f15a24 url(assets/icon/cd-top-arrow.png) no-repeat center 50%;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.3s 0s, visibility 0 0.3s;
  -moz-transition: opacity 0.3s 0s, visibility 0 0.3s;
  transition: opacity 0.3s 0s, visibility 0 0.3s;
  z-index: 999;
  border: 2px solid #000;
  border-radius: 50%;
}

.cd-top.cd-is-visible,
.cd-top.cd-fade-out,
.no-touch .cd-top:hover {
  -webkit-transition: opacity 0.3s 0s, visibility 0 0;
  -moz-transition: opacity 0.3s 0s, visibility 0 0;
  transition: opacity 0.3s 0s, visibility 0 0;
}

.cd-top.cd-is-visible {
  visibility: visible;
  opacity: 1;
}

.cd-top:hover {
  background-color: #fff;
  opacity: 1 !important;
}

// tabs.css
.tabs {
  float: left;
  position: relative;
  width: 100%;
}

.tabs nav {
  text-align: center;
}

.tabs nav ul {
  position: relative;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  margin: 0 auto;
  padding: 0;
  list-style: none;
  justify-content: center;
}

.tabs nav a {
  position: relative;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 2.5;
  color: #8f8f8f;
  padding: 10px 30px;
  font-weight: 700;
}

.tabs nav .tab-current a {
  color: #f15a24;
}

.tabs nav a:focus {
  outline: none;
}

.tabs nav a:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 3px;
  background-color: #8f8f8f;
  z-index: 2;
}

.tabs nav .tab-current a:before {
  width: 100%;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

.tabs nav a:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 0;
  height: 3px;
  background-color: #f15a24;
  z-index: 3;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  margin: auto;
}

.content-wrap {
  float: left;
  position: relative;
  width: 100%;
  height: 70px;
  margin-top: 30px;
}

.content-wrap section {
  opacity: 0;
  overflow: hidden;
  position: relative;
  float: left;
  width: 100%;
  z-index: -1;
  transition: all 0.5s ease-in-out;
}

.content-wrap section.content-current {
  height: auto;
  opacity: 1;
  overflow: visible;
  z-index: 13;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.no-js .content-wrap section {
  display: block;
  padding-bottom: 2em;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}

.no-flexbox nav ul {
  display: block;
}

.no-flexbox nav ul li {
  min-width: 15%;
  display: inline-block;
}

.vehicle_img {
  height: 173px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-slide img {
  max-height: none !important;
}

// responsive.css
/******************************************************
menü
******************************************************/
@media screen and (min-width: 0px) and (max-width: 1066px) {
  .mobilmenu_butonyer * {
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  .mobilmenu_link_ac {
    bottom: 3px;
    color: #888;
    cursor: pointer;
    font-size: 26px;
    left: 0;
    opacity: 1;
    overflow: hidden;
    position: absolute;
    right: 0;
    text-align: center;
    width: 100%;
  }

  .mobilmenu_link_kapat {
    color: #ff0808;
    bottom: 0;
    cursor: pointer;
    font-size: 33px;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    text-align: center;
    width: 100%;
  }

  .mobilmenu .mobilmenu_link_kapat {
    bottom: -2px;
    opacity: 1;
  }

  .mobilmenu .mobilmenu_link_ac {
    bottom: 46px;
    opacity: 0;
  }

  .mobilmenu_butonyer {
    float: left;
    height: 50px;
    position: relative;
    width: 50px;
    font-size: 28px;
    display: block;
    z-index: 16;
    transition: all 0.5s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
  }

  .header_left {
    padding-right: 47px;
  }

  .header_top {
    position: absolute;
    top: 100%;
    background-color: #fff;
    margin-top: 0px;
    left: 0;
    right: 0;
    padding: 10px 4%;
    transform: translate(0, 0px);
    z-index: 20;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    background-color: #fff;
    transition: all 0.5s ease-in-out;
    box-shadow: inset 0px 4px 10px rgb(0 0 0 / 9%);
    -webkit-transform: translate(0, 0px);
    -moz-transform: translate(0, 0px);
    -ms-transform: translate(0, 0px);
    -o-transform: translate(0, 0px);
    flex-wrap: wrap;
    justify-content: center;
  }

  .header_right:focus-within .header_top {
    transform: translate(0, 0);
    visibility: visible;
    opacity: 1;
    z-index: 9;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  .hideMobileMenu {
    transform: translate(0, 0);
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  .profil_menu_drop {
    left: 0;
    margin: auto;
  }

  .header_signin.header_login {
    width: 100%;
    flex: 1;
    text-align: center;
    margin-top: 9px;
  }

  .header_sosyalmedya {
    float: left;
    width: 100%;
    padding-left: 27px;
  }

  .menu > ul > li > a::after {
    display: none;
  }

  .menu > ul > li > a {
    border-bottom: 1px dashed #bccf00;
    width: 100%;
    padding: 12px 18px;
  }

  .rellax {
    transform: translate3d(0px, 0px, 0px) !important;
  }

  .anasayfa_slider_scrollmask {
    display: none;
  }

  .menu_drop {
    position: relative;
    float: left;
    width: 100%;
    top: auto;
    left: auto;
    min-width: auto;
    background-color: #bccf00;
  }

  .menu ul {
    flex-wrap: wrap;
    width: 100%;
  }

  .menu_drop ul li a {
    color: #fff;
    padding: 12px 18px;
    display: inline-block;
    font-weight: 700;
    width: 100%;
    border-bottom: 1px solid #5d8d3e;
  }

  .mobilmenu_butonyer.menu_fix {
    top: 11px;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }
}

/******************************************************
h.b
******************************************************/
@media screen and (min-width: 1368px) and (max-width: 1770px) {
}

/******************************************************
laptop
******************************************************/
@media screen and (min-width: 1025px) and (max-width: 1770px) {
  .our_services_slider {
    width: 100%;
    padding: 0 7%;
  }

  .home_app_download_content {
    height: 374px;
  }

  section.contentpage {
    min-height: 454px;
  }

  header.sitetittle h2 {
    font-size: 30px;
  }

  header.sitetittle {
    padding: 20px 0;
  }

  section.contentpage {
    padding: 20px 0;
  }

  .logo {
    width: 9%;
  }

  .header_motto h1 {
    font-size: 22px;
  }

  .header_motto {
    margin-top: 10px;
    margin-bottom: 1px;
  }

  .header_top {
    margin-top: 11px;
  }

  header.header:after {
    height: 39px;
  }

  nav.content_header_links ul li a {
    padding: 10px 38px;
    padding-left: 0;
    font-size: 16px;
  }

  .content_header_bottom_details {
    font-size: 12px;
  }

  .vehicle_title h3 {
    font-size: 18px;
  }

  label.radio_label2 {
    padding: 14px;
  }

  .vehicle_img {
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: center;
  }

  .siteboxr h3 {
    font-size: 18px;
  }

  .siteboxr_row h4 {
    font-size: 14px;
  }

  .siteboxr_border {
    padding-top: 10px;
    margin-top: 10px;
  }

  .siteboxr {
    padding: 15px 22px;
  }

  .vehicle_title h5 {
    font-size: 16px;
  }

  .our_services_slider .swiper-container {
    max-width: none;
    width: 100%;
  }

  a.next {
    right: 3%;
  }

  a.prev {
    left: 3%;
  }
}

/******************************************************
macbook
******************************************************/
@media screen and (min-width: 1025px) and (max-width: 1300px) {
  .header_motto {
    margin-top: 15px;
    margin-bottom: 1px;
    padding-bottom: 8px;
  }

  header.header:after {
    height: 34px;
  }

  .section {
    zoom: 0.8;
    /*OldIEonly*/
    -moz-transform: scale(0.8);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
  }

  .header section.section {
    position: relative;
    z-index: 10;
  }

  .home_hero section.section {
    position: relative;
    z-index: 10;
  }

  .home_app_download_img {
    bottom: -42px;
  }

  .our_services_slider .swiper-container {
    max-width: none;
    width: 79%;
  }
}

/******************************************************
ipadyatay
******************************************************/
@media only screen and (max-width: 1066px) {
  .our_services_slider {
    width: 100%;
    margin-left: 0;
    padding: 0 5%;
  }

  .vehicle_img {
    height: 240px;
  }

  .ourservicesslider .swiper-slide {
    opacity: 0.4;
  }

  .ourservicesslider .swiper-slide-visible {
    opacity: 1;
  }

  .home_app_download_content {
    height: 298px;
  }

  .footer_bottom.rows {
    flex-direction: column;
    text-align: center;
  }

  .footer_links {
    margin-top: 15px;
  }

  .footer_bottom.rows {
    flex-direction: column;
    text-align: center;
  }

  .footer_links {
    margin-top: 15px;
  }

  .footer_app_icon {
    text-align: center;
  }

  .footer_app_icon a {
    margin: 0 10px;
  }

  .home_transfer_content {
    flex-wrap: wrap;
  }

  .home_transfer_box {
    line-height: normal;
    width: 48% !important;
    flex: auto;
    margin-bottom: 5px !important;
  }

  section.home_hero.rows {
    height: a;
  }

  .hero_logos {
    height: auto;
    padding: 50px 0;
  }

  section.home_hero.rows {
    height: auto;
  }

  .home_transfer_tab {
    height: auto;
    padding: 33px 0px;
  }

  .content-wrap {
    height: 224px;
  }

  section.home_hero.rows {
    background-color: #fff;
    background-size: cover;
    background-repeat: no-repeat;
  }

  section.home_hero:after {
    height: 60%;
  }

  .Vehicles_list ul li {
    width: 100%;
  }

  .Vehicles_list ul {
    column-gap: 0;
    row-gap: 20px;
  }

  .invitebottom h2 {
    font-size: 30px;
  }

  .Promotions_list ul li {
    width: 48%;
  }

  a.next {
    right: 3%;
  }

  a.prev {
    left: 3%;
  }
}

/******************************************************
ipaddik
******************************************************/
@media only screen and (max-width: 1023px) {
  .home_app_download_img {
    position: relative;
    bottom: auto;
    width: 100%;
  }

  .home_app_download_content_right {
    width: 100%;
  }

  .home_app_download_content {
    height: auto;
    display: flex;
    flex-direction: column-reverse;
    padding-top: 66px;
    row-gap: 50px;
  }

  section.home_app_download {
    margin-top: 49px;
  }

  .home_app_download_content_right {
    text-align: center;
    justify-content: center;
  }

  header.sitetittle h2 {
    font-size: 30px;
  }

  footer.footer {
    padding-bottom: 100px;
  }

  .col {
    flex-basis: auto;
  }

  .siteboxr.maps {
    height: 320px;
  }

  .helpdetails {
    padding: 20px;
  }

  .content_header_links {
    display: none;
  }

  .content_header_help {
    justify-content: flex-end;
    padding-right: 6px;
  }

  .app_main {
    padding: 0 !important;
  }

  header.sitetittle {
    padding-bottom: 30px;
  }

  .paymentpage img {
    width: 50px;
    height: auto;
  }

  .home_transfer_box.home_transfer_button {
    margin-top: 14px;
  }
}

/******************************************************
mobilyatay
******************************************************/
@media only screen and (max-width: 767px) {
}

/******************************************************
mobil
******************************************************/
@media only screen and (max-width: 480px) {
  body {
    font-size: 14px;
  }

  .hero_logos_box {
    padding: 23px;
  }

  section.home_hero.rows {
    background-size: contain;
  }

  .hero_logos {
    padding: 18px 0;
  }

  .home_transfer_tab {
    padding: 10px 0;
  }

  section.home_hero:after {
    display: none;
  }

  section.home_hero section.section {
    // height: 100vh
  }

  .content-wrap {
    height: auto;
  }

  .content-wrap section {
    position: relative;
    top: auto;
    bottom: auto;
    right: auto;
    width: auto;
    display: none;
  }

  .content-wrap section.content-current {
    display: block;
  }

  .header_motto h1 {
    font-size: 14px;
  }

  .logo {
    width: 27%;
  }

  header.header:before {
    width: 69%;
  }

  nav.content_header_links ul li a {
    font-size: 12px;
    padding-right: 0;
  }

  nav.content_header_links ul {
    justify-content: space-between;
  }

  nav.content_header_links ul li a {
    font-size: 12px;
    padding-right: 0;
  }

  nav.content_header_links ul {
    justify-content: space-between;
  }

  .content_header_bottom_details {
    font-size: 12px;
    flex-direction: column;
    padding: 10px 0;
    align-items: flex-start;
  }

  .transfer_pickup {
    width: 100%;
  }

  .transfer_distance {
    width: 100%;
    border-left: 0;
    border-right: 0;
    text-align: left;
    padding-left: 0;
    padding-top: 10px;
    border-top: 1px solid #fff;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #fff;
  }

  .transfer_date {
    width: 100%;
    padding-left: 0;
  }

  .biling_tab_row nav.home_transfer_tab_top {
    float: left;
    width: 100%;
  }

  .biling_tab_row .rows.flex-sb-m {
    flex-wrap: wrap;
  }

  .biling_tab_row .tabs nav ul {
    justify-content: flex-start;
    width: 100%;
  }

  .biling_tab_row .tabs nav ul li {
    flex: 1;
  }

  .siteform_row {
    margin-top: 10px !important;
  }

  .siteboxr {
    border-radius: 20px;
    margin-bottom: 20px;
    min-height: 300px;
  }

  @media (min-width: 360px) {
    .siteboxr {
      min-height: 165px !important;
    }
  }

  .Vehicles_list ul {
    row-gap: 0px;
  }

  .Vehicles_list_button {
    bottom: 33px;
  }

  .invite_top svg {
    width: 100%;
    height: 249px;
  }

  .Promotions_list ul li {
    width: 100%;
    column-gap: 0;
  }

  .vehicle_img {
    height: 163px;
  }
}

/******************************************************
iphone 5-
******************************************************/
@media only screen and (max-width: 320px) {
}

.hide {
  display: none;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year {
  margin-right: 0.5rem;
  background-color: #fff;
  border: none 0;
}

.requiredError {
  border: 2px solid red;
}

.custom-loading2 {
  // --background: transparent;
  // ion-backdrop {
  //   // background-color: #fff;
  //   opacity: 0.5 !important;
  // }
  .loading-wrapper {
    -webkit-animation: ld-vortex-out 2s ease-out infinite;
    animation: ld-vortex-out 2s ease-out infinite;
    animation-timing-function: cubic-bezier(0.05, 0, 3, 0.05);

    // background-image: url('../src/assets/images/logo/logo_yeni.svg');

    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    min-width: 90px;
    min-height: 90px;
    box-shadow: none;
    -webkit-box-shadow: none;
    border-radius: 20px;
  }
}

.gap-10 {
  gap: 2.5rem;
}
.light-linear-gradient {
  background: linear-gradient(90deg, rgba(51, 51, 51, 1), rgba(51, 51, 51, 1), #eee, rgba(51, 51, 51, 1) 75%);
  border-top: 0;
}
.justify-between {
  justify-content: space-between;
}

#birthday {
  .p-calendar .p-datepicker {
    top: -100px !important;
  }
}

ion-card {
  border-radius: 15px;
  box-shadow: 0 11px 44px rgba(0, 0, 0, 0.14);
  background-color: #fff;

  ion-card-header {
  }

  ion-card-title {
    font-weight: 700;
    color: inherit;
  }

  ion-card-content {
  }
}
