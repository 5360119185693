.slider-wrap {
  width: 100%;
  float: left;
}
.our_services_slider {
  width: 100%;
  overflow: visible;
  position: relative;
  float: left;
}
.ourservicesslider.swiper-container {
  width: 100%;
  overflow: visible;
}
.ourservicesslider .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ourservicesslider .swiper-slide {
  opacity: 0.1;
}
.contentpage .ourservicesslider .swiper-slide {
  opacity: 0.3;
}
.ourservicesslider .swiper-container {
  overflow: visible;
}
.ourservicesslider .swiper-slide-visible {
  opacity: 1;
}
.contentpage .ourservicesslider .swiper-slide-visible {
  opacity: 1;
}
a.prev {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  left: 9%;
  font-size: 20px;
  color: #f15a24;
  z-index: 10;
}

a.next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  right: 9%;
  font-size: 20px;
  color: #f15a24;
  z-index: 10;
}

.swiper-slide.swiper-slide-active {
  opacity: 1;
}
.swiper-slide-visible {
  opacity: 1 !important;
}
.our_services_slider .swiper-container {
  max-width: 1440px;
  overflow: visible;
}
.swiper-slide {
  opacity: 0.2;
}
.swiper-slide {
  padding: 0 !important;
}
.radioo2 {
  float: left;
  width: 100%;
}
